import React, {useEffect, useState} from 'react';
import {useTranslation} from 'react-i18next';
import {makeStyles} from '@material-ui/core/styles';
import {connect} from 'react-redux';
import {bindActionCreators} from 'redux';
import {isComponentEnabledForUser, isComponentVisibleForUser} from "../../../utils";
import Grid from "@material-ui/core/Grid";
import Paper from "@material-ui/core/Paper";
import {CustomTable, Loading} from "../../elements";
import {ActionButton, EventType} from "../../common";
import TextField from "@material-ui/core/TextField";
import {fetchParkingEventCategories, updateParkingEventCategory} from "../../../redux/actions/parkings";
import {CirclePicker} from 'react-color';

import FormControl from "@material-ui/core/FormControl";
import InputLabel from "@material-ui/core/InputLabel";
import Select from "@material-ui/core/Select";
import MenuItem from "@material-ui/core/MenuItem";

const RED = '#FBC1BA';
const YELLOW = '#FFDA98';
const BLUE = '#B6D3F9';

const PICKER_COLORS = [
    RED, YELLOW, BLUE,
    "#673ab7", "#7e8ef8", "#232020",
    "#009688", "#8bc34a", "#ffc107",
    "#f7ff00", "#e8c2e8", "#795548",
    "#607d8b", "#6bb61d", "#00bcd4",
    "#2ee3c2", "#f5e5e5", "#5e3aba",
]


const useStyles = makeStyles((theme) => ({

    errorMessage: {
        fontSize: 13,
        color: theme.palette.primary.red,
    },
    cardTop: {
        display: 'flex',
        flexDirection: 'column',
        padding: theme.spacing(1, 1, 0, 1),
        height: '100%',
        justifyContent: 'space-between',
    },
    image: {
        maxWidth: 86,
        maxHeight: 86,
    },
    shortName: {
        height: 24,
        fontSize: 16,
        marginLeft: 3,
        marginRight: 3,
        textAlign: 'center',
        textOverflow: 'ellipsis',
        overflow: 'hidden',
        whiteSpace: 'nowrap',
    },
    item: {
        display: 'flex',
        flexDirection: 'column',
        margin: '15px',
        width: 'calc(10% - 30px)',
        height: '150px',
        borderStyle: 'solid',
        borderWidth: '2px',
        boxShadow: '2px 2px 2px #EEEEEE',
        borderColor: '#DEE2E5',
    },
    table: {
        width: '100%',
        flexDirection: 'column',
        display: 'flex',
        padding: theme.spacing(1, 1, 1, 1),
        justifyContent: 'space-between',
    },
    buttonNavigation: {
        display: 'flex',
        background: theme.palette.base.white,
        padding: theme.spacing(2, 2, 2, 2),
        width: '100%',
        alignContent: 'center',
        justifyContent: 'space-between',
    },
    container:{
        height:'100%'
    }
}))

const cellStyle = {
    paddingLeft: 4,
    paddingRight: 4,
    lineHeight: '19px',
    color: '#1B1B28',
    textAlign: 'center',
};


const EventCategoryDetail = ({eventCategory, handleChange, sounds}) => {

    const [error, setError] = useState({});

    const {t} = useTranslation();
    const classes = useStyles();

    const getSound = (soundId) =>{
        if ( soundId ) {
            let soundItem = sounds.find((item)=>item.soundId===soundId);
            if ( soundItem ) {
                return 'data:audio/wav;base64,'+soundItem.content;
            } else {
                return '';
            }
            
        } else {
            return '';
        }
    }

   return (
        <Grid container alignItems="stretch" spacing={2}  >
            <Grid item xs sm={12}>
                <TextField
                    fullWidth
                    required
                    disabled={true}
                    id="eventCategoryId"
                    value={eventCategory.eventCategoryId}
                    label={t('ID')}/>
            </Grid>
            <Grid item xs sm={12}>
                <TextField
                    fullWidth
                    required
                    disabled={true}
                    id="description"
                    value={eventCategory.description}
                    label={t('DESCRIPTION')}/>
            </Grid>
            <Grid item xs sm={6}>
                <FormControl required fullWidth>
                    <InputLabel id="parking-show">
                        {t('SHOW')}
                    </InputLabel>
                    <Select
                        autoWidth
                        value={eventCategory ? ( eventCategory.show ? 'SHOW' : 'DONT_SHOW' ) : ''}
                        id="show"
                        onChange={handleChange('show')}
                    >
                        <MenuItem value='SHOW'>{t('SHOW')}</MenuItem>
                        <MenuItem value='DONT_SHOW'>{t('DONT_SHOW')}</MenuItem>
                    </Select>
                </FormControl>
            </Grid>
            <Grid item xs sm={6}>
                <FormControl required fullWidth>
                    <InputLabel id="parking-label">
                        {t('CATEGORY')}
                    </InputLabel>
                    <Select
                        autoWidth
                        value={eventCategory ? eventCategory.category : ''}
                        id="category"
                        onChange={handleChange('category')}
                    >
                        <MenuItem value='ET_INFO'>{t('INFO')}</MenuItem>
                        <MenuItem value='ET_ERROR'>{t('ERROR')}</MenuItem>
                        <MenuItem value='ET_INCIDENT'>{t('INCIDENT')}</MenuItem>

                    </Select>
                </FormControl>
            </Grid>

            <Grid item xs sm={6}>
                <FormControl required fullWidth>
                    <InputLabel id="parking-label">
                        {t('AUDIO')}
                    </InputLabel>
                    <Select
                        autoWidth
                        value={eventCategory ? eventCategory.soundId : ''}
                        id="soundId"
                        onChange={handleChange('soundId')}
                    >
                        <MenuItem value=''>-</MenuItem>
                        { sounds && 
                            sounds.map((item)=>{
                                return <MenuItem value={item.soundId}>{t(item.name)}</MenuItem>
                            })
                        }

                    </Select>
                </FormControl>
            </Grid>
            <Grid item xs sm={6}>
                <audio src={getSound(eventCategory && eventCategory.soundId)} controls style={{width: '100%'}}> </audio>
            </Grid>

            <Grid item xs sm={12}>
                {/*                <FormControl component="fieldset">
                    <FormLabel component="legend">{t('COLOR')}</FormLabel>
                    <RadioGroup
                        row
                        aria-label="gender"
                        defaultValue="blue"
                        name="radio-buttons-group"
                        value={eventCategory.calculatedColor}
                        onChange={handleChangeColorRadio}
                    >
                        <FormControlLabel value="blue" control={<Radio/>} label={t('BLUE')}/>
                        <FormControlLabel value="red" control={<Radio/>} label={t('RED')}/>
                        <FormControlLabel value="yellow" control={<Radio/>} label={t('YELLOW')}/>
                        <FormControlLabel value="other" control={<Radio/>} label={t('OTHER')}/>
                    </RadioGroup>
                </FormControl>*/}

                <CirclePicker
                    colors={PICKER_COLORS}

                    color={eventCategory.calculatedColor}
                    onChangeComplete={handleChange('color')}
                />


            </Grid>
            {/*            {eventCategory.calculatedColor === 'other' && <Grid item xs sm={6}>
                <ColorPicker
                    fullWidth
                    required
                    floatingLabelText={t('COLOR')}
                    name='color'
                    value={eventCategory && eventCategory.color || '#5985EE'}
                    onChange={handleChange('color')}
                />
            </Grid>
            }*/}
        </Grid>
    );
}


function WizardStep3(props) {
    const classes = useStyles();

    const {
        parking,
        error,
        readOnly,
        userUseCases,
        eventCategories,
        fetchParkingEventCategories,
        isLoading,
        updateParkingEventCategory,
        sounds
    } = props;

    const {t} = useTranslation();

    useEffect(() => {
        if (parking && parking.parkingId>=0) {
            fetchParkingEventCategories(parking.parkingId);
        }
    }, [parking]);



    const [localEventCategories, setLocalEventCategories] = useState();

    const [selectedEventCategory, setSelectedEventCategory] = useState();

    const [isUpdating, setIsUpdating] = useState(false);

    useEffect(() => {
        if (parking) {
            setLocalEventCategories(eventCategories.map(a => {
                let calculatedColor = null;
                if (a.color == 'red') {
                    calculatedColor = RED;
                } else if (a.color == 'blue') {
                    calculatedColor = BLUE;
                } else if (a.color == 'yellow') {
                    calculatedColor = YELLOW;
                } else {
                    calculatedColor = a.color;
                }
                const descStrs = a.category.split('_');
                return {
                    calculatedColor,
                    category: a.category,
                    color: a.color,
                    description: a.description,
                    eventCategoryId: a.eventCategoryId,
                    eventNumber: a.eventNumber,
                    categoryTranslated: t(descStrs[1]),
                    show: a.show,
                    soundId: a.soundId
                }
            }));
        }
    }, [eventCategories])


    const handleEventCategory = (eventCategory) => {
        setSelectedEventCategory(eventCategory);
    }


    const handleChange = (name) => (event) => {
        if (event.hex) {
            let color = null;
            if (event.hex.toUpperCase() == RED) {
                color = 'red';
            } else if (event.hex.toUpperCase() == BLUE) {
                color = 'blue';
            } else if (event.hex.toUpperCase() == YELLOW) {
                color = 'yellow';
            } else {
                color = event.hex;
            }
            setSelectedEventCategory({...selectedEventCategory, color, 'calculatedColor': event.hex});
        } else {
            if ( name === 'show' ) {
                setSelectedEventCategory({...selectedEventCategory, [name]: event.target ? ( event.target.value === 'SHOW' ? true : false ) : event});
            } else {
                setSelectedEventCategory({...selectedEventCategory, [name]: event.target ? event.target.value : event});
            }
        }
    }


    const updateCategoryInner = (eventCategory) => {
        setIsUpdating(true);
        updateParkingEventCategory(eventCategory).then(
            () => {
                setSelectedEventCategory(null);
                setIsUpdating(false);

            }
        ).catch(() => {
            setIsUpdating(false);
        });
    }


    if (isLoading || isUpdating) {
        return (<Loading></Loading>);
    }


    const columns = [
        {
            title: t('SHOW'),
            field: 'show',
            cellStyle,
            render: (rowData) => <>{rowData.show===true?t('SHOW'):t('DONT_SHOW')}</>,
        }, 
        {
            title: t('ID'),
            field: 'eventCategoryId',
            cellStyle,
            render: (rowData) => <>{rowData.eventCategoryId}</>,
        }, {
            title: t('DESCRIPTION'),
            field: 'description',
            cellStyle,
            render: (rowData) => <>{rowData.description}</>,
        },
        {
            title: t('EVENT_TYPE'),
            field: 'categoryTranslated',
            cellStyle,
            render: (rowData) => {
                return (
                    <EventType type={rowData.categoryTranslated} border chipColor={rowData.color}/>
                );
            },
        }];


    const tableOptions = {
        headerStyle: {
            padding: '15px 8px 15px 34px',
            lineHeight: '12px',
            color: '#1B1B28',
            textAlign: 'center',
        },
    };


    return (

        <Grid container spacing={2} style={{flex: 1, height: '100%', overflow: 'hidden', paddingTop: '20px'}}>
            <Grid item md={selectedEventCategory ? 9 : 12} style={{flex: 1, overflow: 'scroll', height: '100%'}}>
                <CustomTable
                    rowsPerPage={eventCategories.length}
                    title=""
                    columns={columns}
                    onRowClick={((evt, category) => {
                        handleEventCategory(category);
                    })}
                    data={localEventCategories}
                    options={{
                        searchFieldAlignment: 'left',
                        search: true,
                        headerStyle: {
                            padding: '15px 8px 15px 34px',
                            borderBottom: '1px solid #DEE2E5',
                            lineHeight: '12px',
                            color: '#1B1B28',
                            textAlign: 'center',
                        },
                        rowStyle: rowData => ({
                            backgroundColor: (selectedEventCategory && selectedEventCategory.tableData && selectedEventCategory.tableData.id === rowData.tableData.id) ? '#a6bef9' : '#FFF'
                        })
                    }}
                    isLoading={isLoading}
                />

            </Grid>
            {!!selectedEventCategory && <Grid item md={3}>
                <div className={classes.title}>
                    <h2>{t('EVENT_CATEGORY_UPDATE')}</h2>
                </div>

                <Paper className={classes.table}>
                    <EventCategoryDetail
                        eventCategory={selectedEventCategory} handleChange={handleChange} sounds={sounds}
                    />
                    <div className={classes.buttonNavigation}>
                        <ActionButton
                            action="close"
                            handleClick={() => {
                                setSelectedEventCategory(null);
                            }}
                        />
                        {isComponentVisibleForUser(UC_CATEGORY_EDIT, userUseCases) &&
                        <ActionButton
                            disabled={!isComponentEnabledForUser(UC_CATEGORY_EDIT, userUseCases)}
                            action="save"
                            handleClick={() => {
                                updateCategoryInner(selectedEventCategory)
                            }}
                        />}
                    </div>
                </Paper>
            </Grid>}
        </Grid>


    );
}

const UC_CATEGORY_EDIT = 'UC0634';

WizardStep3.propTypes = {};

WizardStep3.defaultProps = {};

const mapStateToProps = (store) => ({
    eventCategories: store.parkingsData.eventCategoriesForParkingConfiguration,
    isLoading: store.parkingsData.isFetchingEventCategoriesForParkingConfiguration,

});

const mapDispatchToProps = (dispatch) => bindActionCreators({
    fetchParkingEventCategories, updateParkingEventCategory,
}, dispatch);

export default connect(
    mapStateToProps,
    mapDispatchToProps,
)(WizardStep3);

