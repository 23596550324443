import httpService from './http.service';

const getInfoBars = () => httpService
    .get('/infobar')
    .then(({data}) => data)
    .catch((err) => Promise.reject(err.response));

const getInfoBar = (infoBarId) => httpService
    .get('/infobar/'+infoBarId)
    .then(({data}) => data)
    .catch((err) => Promise.reject(err.response));

const createInfoBar = (playList) => httpService
    .post(`/infobar/create`, playList)
    .then(({data}) => data)
    .catch((err) => {
        throw err;
    });

const updateInfoBar = (playList) => httpService
    .put(`/infobar/update`, playList)
    .then(({data}) => data)
    .catch((err) => Promise.reject(err));

// const deletePlayList = (playListId) => httpService
//     .remove(`/playlist/delete/${playListId}`)
//     .then()
//     .catch((err) => Promise.reject(err));

export default {
    getInfoBars,
    getInfoBar,
    createInfoBar,
    updateInfoBar,
};
