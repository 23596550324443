import React, {useEffect, useState} from 'react';
import {bindActionCreators} from 'redux';
import {connect} from 'react-redux';
import {useTranslation} from 'react-i18next';
import {makeStyles} from '@material-ui/core/styles';
import {getErrorMessage, isComponentEnabledForUser, isComponentVisibleForUser} from 'utils';
import {
    createParking,
    fetchAllparkingsForParkingConfiguration,
    restart,
    tryConnectParking, tryTerminate,
    updateParking
} from 'redux/actions/parkings';
import ToolBar from './ToolBar';
import Typography from "@material-ui/core/Typography";
import classnames from 'classnames';
import {Link} from 'react-router-dom';
import PerfectScrollbar from "react-perfect-scrollbar";
import PMCSnackbar from 'components/common/Snackbar';
import ParkingWizard from "components/modals/ParkingWizard";
import {fetchAllSubjects} from "../../../redux/actions/subjects";
import Parkings from "./Parkings";
import Parking from "../../../components/modals/Parking";
import {fetchAllUsers} from "../../../redux/actions/users";
import ParkingRestart from "../../../components/modals/ParkingRestart";
import Grid from "@material-ui/core/Grid";
import {ToggleButton} from "@material-ui/lab";
import TocIcon from '@material-ui/icons/Toc';

const useStyles = makeStyles((theme) => ({
    breadcrumb: {
        display: 'flex',
        alignItems: 'center',
        fontWeight: 300,
        color: theme.palette.base[500],
    },
    title: {
        marginBottom: theme.spacing(3.75),
        marginTop: theme.spacing(3.75),
        textTransform: 'uppercase',
    },
    separator: {
        marginLeft: theme.spacing(1.5),
        marginRight: theme.spacing(1.5),
    },
    activeLink: {
        color: theme.palette.secondary.main,
    },
    navLink: {
        cursor: 'pointer',
    },
    container: {
        background: theme.palette.base.white,
        marginTop: theme.spacing(4),
    },
}));


function ParkingConfiguration(props) {
    const {
        userUseCases,
        parkingsForParkingConfiguration,
        fetchAllparkingsForParkingConfiguration,
        isFetchingParkingsForParkingConfiguration,
        createParking,
        updateParking,
        parking,
        user,
        subjects,
        parkingUsers,
        fetchAllSubjects,
        fetchAllUsers,
        tryConnectParking,
        sipCall,
        sipStatus,
        restart,
        isRestarting,
        tryTerminate,
        sseParkings,
    } = props;


    const classes = useStyles();

    const {t} = useTranslation();


    const protocols = [
        {
            value: 'MQ',
            label: t('MQ'),
            key: 'MQ',
            eval: (parking) => {
                return parking.connectionType === 'MQ';
            }
        },
        {
            value: 'GMTP',
            label: t('GMTP'),
            key: 'GMTP',
            eval: (parking) => {
                return parking.connectionType === 'GMTP';
            }
        },
        {
            value: 'NOT_SELECTED',
            label: t('NOT_SELECTED'),
            key: 'NOT_SELECTED',
            eval: (parking) => {
                return (!parking.connectionType || parking.connectionType.length === 0);
            }
        },
    ];

    const connections = [
        {
            value: 'CONNECTED',
            label: t('CONNECTED'),
            key: 'CONNECTED',
            eval: (parking) => {
                return parking.communicationStatus === 'OK';
            }
        },
        {
            value: 'DISCONNECTED',
            label: t('DISCONNECTED'),
            key: 'DISCONNECTED',
            eval: (parking) => {
                return parking.communicationStatus !== 'OK';
            }
        },
    ];

    const autoConnects = [
        {
            value: 'YES',
            label: t('YES'),
            key: 'YES',
            eval: (parking) => {
                return parking.connect;
            }
        },
        {
            value: 'NO',
            label: t('NO'),
            key: 'NO',
            eval: (parking) => {
                return !parking.connect;
            }
        },
    ];


    const [isFetchedParkings, setIsFetchedParkings] = useState(false);
    const [isLoading, setIsLoading] = useState(true);

    const [snackbarOpen, setSnackbarOpen] = useState(false);
    const [snackbarMessage, setSnackbarMessage] = useState('');
    const [severity, setSeverity] = useState('error');

    const setDeletionSnackbarMessage = (message) => {
        setSeverity('error');
        setSnackbarMessage(message)
    }

    const [parkingConfigurationModel, setParkingConfigurationModel] = useState({
        parkingDetailOpen: false,
        parking: {},
        isNew: true
    });

    const [parkingConfigurationModelBasic, setParkingConfigurationModelBasic] = useState({
        parkingDetailOpen: false,
        parking: {},
        isNew: true
    });


    const [parkingRestartModel, setParkingRestartModel] = useState({
        open: false,
        parking: {},
    });


    const handleCloseSnackbar = (event, reason) => {
        if (reason === 'clickaway') {
            return;
        }
        setSnackbarOpen(false);
    };


    useEffect(() => {
        if (!isFetchedParkings) {
            setIsLoading(true);
            fetchAllparkingsForParkingConfiguration(user
                && user.subjectId).then(
                () => {
                    fetchAllSubjects().then(
                        fetchAllUsers().then(
                            () => {
                                setIsFetchedParkings(true);
                                setIsLoading(false);
                            }
                        ).catch((err) => {
                            setIsFetchedParkings(true);
                            setIsLoading(false);
                        })
                    ).catch((err) => {
                        setIsFetchedParkings(true);
                        setIsLoading(false);
                    });


                }
            ).catch((err) => {
                setIsFetchedParkings(true);
                setIsLoading(false)
            });
        }

    }, [fetchAllparkingsForParkingConfiguration, isFetchedParkings]);


    const [selectedName, setSelectedName] = useState('');

    const handleSelectedName = (value) => {
        setSelectedName(value.target.value);
    };


    const [selectedProtocol, setSelectedProtocol] = useState([]);


    const handleSelectProtocol = (value) => {
        setSelectedProtocol(value);
    };


    const matchProtocol = (pos) => {
        let res = false;
        for (let i = 0; i < selectedProtocol.length; i++) {
            res = selectedProtocol[i].eval(pos);
            if (res) {
                return true;
            }
        }
        return false;
    }


    const [selectedConnection, setSelectedConnection] = useState([]);


    const handleSelectConnection = (value) => {
        setSelectedConnection(value);
    };


    const matchConnection = (pos) => {
        let res = false;
        for (let i = 0; i < selectedConnection.length; i++) {
            res = selectedConnection[i].eval(pos);
            if (res) {
                return true;
            }
        }
        return false;
    }


    const [selectedAutoConnect, setSelectedAutoConnect] = useState([]);


    const handleSelectAutoConnect = (value) => {
        setSelectedAutoConnect(value);
    };


    const matchAutoConnect = (pos) => {
        let res = false;
        for (let i = 0; i < selectedAutoConnect.length; i++) {
            res = selectedAutoConnect[i].eval(pos);
            if (res) {
                return true;
            }
        }
        return false;
    }
    const [subjectValues, setSubjectValues] = useState([]);

    useEffect(() => {
        if (subjects) {
            const filSubjects = [];
            for (let i = 0; i < subjects.length; i++) {
                const a = subjects[i];
                filSubjects.push({value: a.subjectId, label: a.name, key: a.subjectId});
            }
            setSubjectValues(filSubjects);
        }
    }, [subjects]);

    const [selectedSubject, setSelectedSubject] = useState([]);
    const handleSelectSubject = (value) => {
        setSelectedSubject(value);

    };

    const matchSubject = (pos) => {
        let res = false;
        for (let i = 0; i < selectedSubject.length; i++) {
            res = selectedSubject[i].value == pos.subjectId;
            if (res) {
                return true;
            }
        }
        return false;
    }

    const getSubjectName = (subjectId, subjects) => {
        if (subjects && subjectId) {
            const sub = subjects.find(a => a.subjectId == subjectId);
            if (sub) {
                return sub.name;
            }
        }
    }

    const clearErrors = () => {
        setSnackbarMessage('');
        setSnackbarOpen(false);
    }

    useEffect(() => {
        const filParkings = [];
        for (let i = 0; i < parkingsForParkingConfiguration.length; i++) {
            let matchCriteria = true;

            if (selectedName && selectedName.trim().length > 0) {
                if (parkingsForParkingConfiguration[i].parkingName) {
                    if (!(parkingsForParkingConfiguration[i].parkingName.toLowerCase().includes(selectedName.toLowerCase()))) {
                        matchCriteria = false;
                    }
                }
            }

            if (selectedProtocol && selectedProtocol.length > 0) {
                const res = matchProtocol(parkingsForParkingConfiguration[i]);
                if (!res) {
                    matchCriteria = false;
                }
            }

            if (selectedConnection && selectedConnection.length > 0) {
                const res = matchConnection(parkingsForParkingConfiguration[i]);
                if (!res) {
                    matchCriteria = false;
                }
            }


            if (selectedAutoConnect && selectedAutoConnect.length > 0) {
                const res = matchAutoConnect(parkingsForParkingConfiguration[i]);
                if (!res) {
                    matchCriteria = false;
                }
            }
            if (selectedSubject && selectedSubject.length > 0) {
                const res = matchSubject(parkingsForParkingConfiguration[i]);
                if (!res) {
                    matchCriteria = false;
                }
            }


            if (matchCriteria) {
                filParkings.push({
                    ...parkingsForParkingConfiguration[i],
                    subjectName: getSubjectName(parkingsForParkingConfiguration[i].subjectId, subjects)
                })
                ;
            }


        }
        setFilteredParkings(filParkings);
    }, [parkingsForParkingConfiguration, selectedName, selectedProtocol, selectedConnection, selectedAutoConnect, selectedSubject, subjects]);


    const [filteredParkings, setFilteredParkings] = useState([]);
    const [table, setTable] = useState(false);

    const handleParkingDetail = (parking, isNew,) => {
        clearErrors();
        setParkingConfigurationModel({isNew, parking, parkingDetailOpen: true});
    };

    const closeParkingDetail = () => {
        setParkingConfigurationModel({isNew: false, parking: {}, parkingDetailOpen: false});
    }


    const handleParkingDetailBasic = (parking, isNew,) => {
        clearErrors();
        setParkingConfigurationModelBasic({isNew, parking, parkingDetailOpen: true});
    };

    const closeParkingDetailBasic = () => {
        setParkingConfigurationModelBasic({isNew: false, parking: {}, parkingDetailOpen: false});
    }

    const handleRestart = (parking) => {
        clearErrors();
        setParkingRestartModel({parking, open: true});
    };


    const closeRestart = () => {
        setParkingRestartModel({parking: {}, open: false});
    }


    const restartInner = (parking, force) => {
        restart(parking, force).then((prod) => {
            setSeverity('success')
            setSnackbarMessage(t('CMD_RESTART_DONE'));
            setSnackbarOpen(true);
            closeRestart();
        }).catch((error) => {
            setSeverity('error');
            setSnackbarMessage(getErrorMessage(error, t));
            setSnackbarOpen(true);
            closeRestart();
        });

    };


    return (
        <PerfectScrollbar
            options={{
                suppressScrollX: true,
                minScrollbarLength: 50,
            }}
        >

            <div className={classes.breadcrumb}>
                {isComponentVisibleForUser(UC_ADMIN_DASHBOARD, userUseCases) &&

                    <Link style={{textDecoration: 'none'}}
                          to={isComponentEnabledForUser(UC_ADMIN_DASHBOARD, userUseCases) ? `/${process.env.REACT_APP_PATH}/administration` : "#"}>

                        <Typography variant="button" className={classnames(classes.activeLink, classes.navLink)}>
                            {t('ADMINISTRATION')}
                        </Typography>
                    </Link>
                }
            </div>

            <Typography variant="h4" className={classes.title}>
                {t('PARKING_CONFIGURATION')}
            </Typography>


            <PMCSnackbar open={snackbarOpen} onClose={handleCloseSnackbar} severity={severity}
                         message={snackbarMessage}/>

            <div className={classes.container}>

                <ToolBar
                    autoConnects={autoConnects}
                    selectedAutoConnect={selectedAutoConnect}
                    handleSelectAutoConnect={handleSelectAutoConnect}
                    connections={connections}
                    selectedConnection={selectedConnection}
                    handleSelectConnection={handleSelectConnection}
                    protocols={protocols}
                    selectedProtocol={selectedProtocol}
                    handleSelectProtocol={handleSelectProtocol}
                    selectedName={selectedName}
                    handleSelectedName={handleSelectedName}
                    userUseCases={userUseCases}
                    handleParkingDetailBasic={handleParkingDetailBasic}
                    subjectValues={subjectValues}
                    selectedSubject={selectedSubject}
                    handleSelectSubject={handleSelectSubject}
                />
                <Grid container>

                    <Grid item sm={11}>
                    </Grid>
                    <Grid item sm={1}>
                        <ToggleButton
                            style={{width: '30px', height: '30px'}}
                            value="check"
                            selected={table}
                            onChange={() => {
                                setTable(!table);
                            }}
                        >
                            <TocIcon/>
                        </ToggleButton>
                    </Grid>
                </Grid>

                <Parkings
                    refresh={() => {
                        setIsFetchedParkings(false);
                    }}
                    table={table}
                    handleRestart={handleRestart}
                    userUseCases={userUseCases}
                    parkings={filteredParkings}
                    handleParkingDetail={handleParkingDetail}
                    handleParkingDetailBasic={handleParkingDetailBasic}
                    isLoading={isFetchingParkingsForParkingConfiguration}
                    setDeletionSnackbarOpen={snackbarOpen}
                    sseParkings={sseParkings}
                    setDeletionSnackbarError={setDeletionSnackbarMessage}
                />

            </div>


            <ParkingWizard parkingConfigurationModel={parkingConfigurationModel}
                           userUseCases={userUseCases}
                           handleClose={closeParkingDetail}
                           snackbarOpen={snackbarOpen}
                           setSnackbarOpen={setSnackbarOpen}
                           subjects={subjects}
                           sipCall={sipCall}
                           sipStatus={sipStatus}
            />

            <Parking parkingConfigurationModel={parkingConfigurationModelBasic}
                     userUseCases={userUseCases}
                     connect={tryConnectParking}
                     terminate={tryTerminate}
                     createParking={createParking}
                     updateParking={updateParking}
                     handleClose={closeParkingDetailBasic}
                     subjects={subjects}
                     parkingUsers={parkingUsers}
                     sseParkings={sseParkings}
                     setIsFetchedParkings={setIsFetchedParkings}
            />

            <ParkingRestart restartModel={parkingRestartModel}
                            userUseCases={userUseCases}
                            handleClose={closeRestart}
                            snackbarOpen={snackbarOpen}
                            setSnackbarOpen={setSnackbarOpen}
                            restart={restartInner}
                            isRestarting={isRestarting}
            />

        </PerfectScrollbar>
    );
}

ParkingConfiguration.propTypes = {};

const UC_ADMIN_DASHBOARD = 'UC0600';

const mapStateToProps = (store) => ({
    user: store.authData.user,
    parkingsForParkingConfiguration: store.parkingsData.parkingsForParkingConfiguration,
    sseParkings: store.parkingsData.parkings || [],
    isFetchingParkingsForParkingConfiguration: store.parkingsData.isFetchingParkingsForParkingConfiguration,
    subjects: store.subjectsData.subjects,
    parkingUsers: store.usersData.parkingUsers,
    isRestarting: store.parkingsData.isRestarting,

});

const mapDispatchToProps = (dispatch) => bindActionCreators({
    fetchAllparkingsForParkingConfiguration,
    createParking,
    updateParking,
    tryConnectParking,
    fetchAllSubjects,
    fetchAllUsers,
    restart,
    tryTerminate,
}, dispatch);

export default connect(
    mapStateToProps,
    mapDispatchToProps,
)(ParkingConfiguration);
