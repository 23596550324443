import React, {useEffect, useState} from 'react';
import {useTranslation, withTranslation} from 'react-i18next';
import {Redirect, Switch, withRouter, matchPath} from 'react-router-dom';
import CssBaseline from '@material-ui/core/CssBaseline';
import {makeStyles} from '@material-ui/core/styles';
import PublicRoute from 'routes/PublicRoute';
import Management from 'containers/Management';
import Access from 'containers/Access';
import Parking from 'containers/Parking';
import Cashdesk from 'containers/Cashdesk';
import ProductAdministration from 'containers/Administration/ProductAdministration';
import MobileManagement from 'containers/Mobile';
import About from 'containers/About';
import Download from 'containers/Download';
import {LeftDrawer, TopBar} from 'components/layout';
import LiveData from './containers/EventSourceTest/LiveData';
import {initializeSseParkingES} from './services/parking.sse';
import store from './redux/store';
import {getErrorMessage, getQueryVariable, getSuccessMessage} from './utils';
import SipCard from 'components/common/SipCard';
import {isComponentEnabledForUser} from 'utils';
import Administration from 'containers/Administration';
import ParkingConfiguration from "./containers/Administration/ParkingConfiguration";
import PosConfiguration from "./containers/Administration/PosConfiguration";
import RoleAdministration from "./containers/Administration/RoleAdministration"
import UserAdministration from './containers/Administration/UserAdministration';
import PlayListAdministration from './containers/Administration/PlayListAdministration';
import { useDispatch, useSelector } from 'react-redux';
import PMCSnackbar from 'components/common/Snackbar';
import InfoBarAdministration from 'containers/Administration/InfoBarAdministration';


const useStyles = makeStyles((theme) => ({
    root: {
        display: 'flex',
        color: theme.palette.base[800],
        height: '100%',
    },
    toolbar: {
        height: 70,
        paddingRight: theme.spacing(3), // keep right padding when drawer closed
        color: theme.palette.base[800],
    },
    content: {
        flexGrow: 1,
        height: '100%',
        overflow: 'auto',
    },
    appBarSpacer: {
        height: 70,
        ...theme.mixins.toolbar,
    },
    appContent: {
        height: 'calc(100% - 70px)',
        padding: theme.spacing(1.875),

        '& > .scrollbar-container': {
            padding: theme.spacing(1.875),
        },
    },
    sip: {
        position: 'absolute',
        bottom: 0,
        left: 'calc(100% - 500px)',
        zIndex: 1001,
    },
    success: {backgroundColor: theme.palette.primary.main},
    error: {backgroundColor: theme.palette.primary.red, color: theme.palette.base.white},
    warning: {backgroundColor: theme.palette.primary.yellow},
    info: {backgroundColor: theme.palette.secondary.main},
    white: {backgroundColor: theme.palette.base.white},
    zIndex: {zIndex: 2000},
}));


const App = (props) => {
    const {
        handleCloseErrorSnackbar,
        handleCloseSuccessSnackbar,
        httpserviceError,
        httpserviceSuccess
    } = props;

    const {t} = useTranslation();

    const classes = useStyles();
    const [token, setToken] = useState(null);
    const [selectedHostNameSip, setSelectedHostNameSip] = useState(null);
    const [selectedParkingSip, setSelectedParkingSip] = useState(null);

    const getToken = () => {
        const kcToken = localStorage.getItem('kc_token');
        setToken(kcToken);
    };

    useEffect(() => {
        getToken();
    });

    const [openFulltext, setOpenFulltext] = useState(false);
    const handleFulltextClose = () => {
        setOpenFulltext(false);
    };
    const handleFulltextOpen = () => {
        setOpenFulltext(true);
    };

    const isParkingDashboard = !!matchPath(
        props.location.pathname, {
            path: `/${process.env.REACT_APP_PATH}/parking`,
            exact: true
        }
    );


    const handleKeyDown = (event) => {
        /*        debugger
                let charCode = String.fromCharCode(event.which).toLowerCase();
                if ((event.ctrlKey || event.metaKey) && charCode === 'b') {
                    handleFulltextOpen();
                }*/
    }

    const [open, setOpen] = useState(false);
    const handleDrawerOpen = () => {
        setOpen(true);
    };
    const handleDrawerClose = () => {
        setOpen(false);
    };

    useEffect(() => {
        if (token) {
            initializeSseParkingES(token, store, localStorage.getItem('pos_id'), setToken);
        }
    }, [token]);

    const simpleWindow = getQueryVariable('simple') === 'Y';
    const existParentWindow = window.parent.opener !== null;

    const soundId = useSelector((state) => state.parkingsData.soundId);
    const sounds = useSelector((state) => state.soundsData.sounds);
    const infoBar = useSelector((state) => state.infoBarData.infoBar);
 
    useEffect(()=>{
      if ( soundId ) {
        let tmp = sounds.find((item)=>item.soundId===soundId);
        let audio = new Audio('data:audio/wav;base64,'+tmp.content);
        const audioPromise = audio.play()
        if (audioPromise !== undefined) {
          audioPromise
            .then(_ => {
              // console.log('autoplay ok');
            })
            .catch(err => {
              console.log('autoplay error', err);
            })
        } else {
          console.log('autoplay audioPromise fail');
        }
      }
    }, [soundId]);

    return (
        <div className={classes.root}
             onKeyPress={handleKeyDown}>
            <CssBaseline/>

            <TopBar
                handleFulltextOpen={handleFulltextOpen}
                userUseCases={props.userUseCases}
                open={open}
                simpleWindow={simpleWindow}
                handleDrawerOpen={handleDrawerOpen}
                posStatutes={props.posStatutes}
                showFulltext={isParkingDashboard}
                infoBar={infoBar}
            />

            <PMCSnackbar 
                open={httpserviceError && httpserviceError.apiName} 
                onClose={handleCloseErrorSnackbar} 
                severity={'error'} 
                message={getErrorMessage(httpserviceError, t)} 
            />

            <PMCSnackbar 
                open={httpserviceSuccess && httpserviceSuccess.apiName} 
                onClose={handleCloseSuccessSnackbar} 
                severity={'success'} 
                message={getSuccessMessage(httpserviceSuccess, t)} 
            />

            {!simpleWindow && (
                <LeftDrawer open={open} handleDrawerClose={handleDrawerClose} userUseCases={props.userUseCases}/>
            )}


            <main className={classes.content}>
                <div className={classes.appBarSpacer}/>
                <div className={classes.appContent}>
                    <Switch>
                        {isComponentEnabledForUser(UC_MENU_LOCAL_PARKING_DASHBOARD, props.userUseCases) &&
                            <Redirect exact from="/" to={`/${process.env.REACT_APP_PATH}/parking`}/>
                        }
                        {isComponentEnabledForUser(UC_MENU_LOCAL_PARKING_DASHBOARD, props.userUseCases) &&
                            <Redirect exact from={`/${process.env.REACT_APP_PATH}`}
                                      to={`/${process.env.REACT_APP_PATH}/parking`}/>
                        }
                        {isComponentEnabledForUser(UC_MENU_LOCAL_PARKING_DASHBOARD, props.userUseCases) &&
                        <PublicRoute exact path={`/${process.env.REACT_APP_PATH}/parking`} component={Parking}
                                     props={{...props, openFulltext, handleFulltextClose}}/>}

                        {isComponentEnabledForUser(UC_MENU_ADMINISTRATION_PRODUCTS, props.userUseCases) &&
                        <PublicRoute exact path={`/${process.env.REACT_APP_PATH}/administration/products`}
                                     component={ProductAdministration}
                                     props={props}/>}
                        {isComponentEnabledForUser(UC_MENU_ADMINISTRATION_PARKING, props.userUseCases) &&
                        <PublicRoute exact path={`/${process.env.REACT_APP_PATH}/administration/parkings`}
                                     component={ParkingConfiguration}
                                     props={props}/>}
                        {isComponentEnabledForUser(UC_MENU_ADMINISTRATION_POS, props.userUseCases) &&
                        <PublicRoute exact path={`/${process.env.REACT_APP_PATH}/administration/poslist`}
                                     component={PosConfiguration}
                                     props={props}/>}
                        {isComponentEnabledForUser(UC_MENU_ADMINISTRATION_ROLES, props.userUseCases) &&
                        <PublicRoute exact path={`/${process.env.REACT_APP_PATH}/administration/rolelist`}
                                     component={RoleAdministration}
                                     props={props}/>}
                        {isComponentEnabledForUser(UC_MENU_ADMINISTRATION_USERS, props.userUseCases) &&
                        <PublicRoute exact path={`/${process.env.REACT_APP_PATH}/administration/userlist`}
                                     component={UserAdministration}
                                     props={props}/>}
                        {isComponentEnabledForUser(UC_MENU_ADMINISTRATION_PLAY_LISTS, props.userUseCases) &&
                        <PublicRoute exact path={`/${process.env.REACT_APP_PATH}/administration/playlist`}
                                     component={PlayListAdministration}
                                     props={props}/>}
                        {isComponentEnabledForUser(UC_MENU_ADMINISTRATION_INFO_BARS, props.userUseCases) &&
                        <PublicRoute exact path={`/${process.env.REACT_APP_PATH}/administration/infobar`}
                                     component={InfoBarAdministration}
                                     props={props}/>}
                        {isComponentEnabledForUser(UC_MENU_ACCESS, props.userUseCases) &&
                        <PublicRoute exact path={`/${process.env.REACT_APP_PATH}/access`} component={Access}
                                     props={props}/>}

                        {isComponentEnabledForUser(UC_MENU_MANAGEMENT, props.userUseCases) &&
                        <PublicRoute exact path={`/${process.env.REACT_APP_PATH}/management`} component={Management}
                                     props={props}/>}
                        {(isComponentEnabledForUser(UC_MENU_CASHDESK_DASHBOARD, props.userUseCases) ||
                                isComponentEnabledForUser(UC_MENU_CASHDESK_TOOL, props.userUseCases)) &&
                            <PublicRoute exact path={`/${process.env.REACT_APP_PATH}/cashdesk`} component={Cashdesk}
                                         props={props}/>
                        }
                        {isComponentEnabledForUser(UC_MENU_ADMINISTRATION, props.userUseCases) &&
                            <PublicRoute exact path={`/${process.env.REACT_APP_PATH}/administration`}
                                         component={Administration} props={props}/>}
                        {isComponentEnabledForUser(UC_MENU_MOBILE, props.userUseCases) &&
                        <PublicRoute exact path={`/${process.env.REACT_APP_PATH}/mobile`} component={MobileManagement}
                                     props={props}/>}
                        <PublicRoute exact path={`/${process.env.REACT_APP_PATH}/test`} component={LiveData}
                                     props={props}/>
                        {isComponentEnabledForUser(UC_MENU_ABOUT, props.userUseCases) &&
                        <PublicRoute exact path={`/${process.env.REACT_APP_PATH}/about`} component={About}
                                     props={props}/>}
                        {isComponentEnabledForUser(UC_MENU_DOWNLOAD, props.userUseCases) &&
                        <PublicRoute exact path={`/${process.env.REACT_APP_PATH}/download`} component={Download}
                                     props={props}/>}
                    </Switch>

                </div>
                <div className={classes.sip}>
                    {props.isSIP && !simpleWindow && <SipCard
                        init={props.init}
                        sipStatus={props.sipStatus}
                        calls={props.calls}
                        reject={props.reject}
                        deleteCalls={props.deleteCalls}
                        sipCall={props.sipCall}
                        remote={props.remote}
                        sipHangUp={props.sipHangUp}
                        pauseQueue={props.pauseQueue}
                        unpauseQueue={props.unpauseQueue}
                        sipToggleHoldResume={props.sipToggleHoldResume}
                        userUseCases={props.userUseCases}
                    />
                    }
                </div>

            </main>
        </div>
    );
};
const UC_MENU_LOCAL_PARKING_DASHBOARD = 'UC0002';
const UC_MENU_CASHDESK_DASHBOARD = 'UC0402';
const UC_MENU_CASHDESK_TOOL = 'UC0500';
const UC_MENU_ADMINISTRATION = 'UC0600';
const UC_MENU_ADMINISTRATION_PRODUCTS = 'UC0610';
const UC_MENU_ADMINISTRATION_PARKING = 'UC0630';
const UC_MENU_ADMINISTRATION_POS = 'UC0640';
const UC_MENU_ADMINISTRATION_ROLES = 'UC0650';
const UC_MENU_ADMINISTRATION_USERS = 'UC0660';
const UC_MENU_ADMINISTRATION_PLAY_LISTS = 'UC0670';
const UC_MENU_ADMINISTRATION_INFO_BARS = 'UC0680';
const UC_MENU_ACCESS = 'UC0900';
const UC_MENU_MANAGEMENT = 'UC0057';
const UC_MENU_MOBILE = 'UC0068';
const UC_MENU_ABOUT = 'UC0076';
const UC_MENU_DOWNLOAD = 'UC0074';

export default withRouter(withTranslation('common')(App));
