import React, {useState,} from 'react';
import {useTranslation} from 'react-i18next';
import PropTypes from 'prop-types';
import {makeStyles} from '@material-ui/core/styles';
import {CustomTable, Loading} from 'components/elements';
import {connect} from 'react-redux';
import BottomNavigationAction from "@material-ui/core/BottomNavigationAction";
import BottomNavigation from "@material-ui/core/BottomNavigation";
import {ActionButton} from "../../../components/common";
import {isComponentEnabledForUser, isComponentVisibleForUser} from "../../../utils";
import Typography from "@material-ui/core/Typography";
import EditIcon from '@material-ui/icons/Edit';
import InfoIcon from '@material-ui/icons/Info';
import WindowIcon from '@material-ui/icons/ViewHeadline';
import CertificateIcon from '@material-ui/icons/VerifiedUser';
import SecurityIcon from '@material-ui/icons/Security';
import Tooltip from '@material-ui/core/Tooltip';
import IconButton from "@material-ui/core/IconButton";
import moment from 'moment';
import InfoWindow from 'components/modals/InfoBar/InfoWindow';

const useStyles = makeStyles((theme) => ({
    container: {
        width: '100%',
        display: 'flex',
        flexWrap: 'wrap',
        paddingBottom: 100,
        paddingTop: 20,
    },
    actionIcon: {
        fontSize: 20,
    },
    actions: {
        display: 'flex',
        width: '100%',
        justifyContent: 'space-around',
    },
    parkingCard: {
        width: 384,
        height: 200,
        margin: theme.spacing(2.5),
        display: 'flex',
        flexDirection: 'column',
        justifyContent: 'space-between',
        background: theme.palette.base[100],
        borderStyle: 'solid',
        borderWidth: '2px',
        cursor: 'pointer',
        borderColor: theme.palette.base[200],
        boxShadow: '10px 10px 8px #EEEEEE',
    },
    disabled: {
        backgroundImage: 'linear-gradient(135deg, #FFFFFF00 25%, #FFFFFF3F 25%, #FFFFFF3F 50%, #FFFFFF00 50%, #FFFFFF00 75%, #FFFFFF3F 75%, #FFFFFF3F 100%)',
        backgroundSize: '20px 20px',
    },
    root: {
        width: 380,
        height: 40,
        background: theme.palette.base[100],
        display: 'flex',
        flexDirection: 'row',
        justifyContent: 'space-between',
    },
    state: {
        display: 'flex',
    },
    row1: {
        width: 380,
        height: 90,
        fontSize: 14,
        padding: 5,
        marginTop: -10,
        display: 'flex',
        flexDirection: 'row',
        alignItems: 'center',
        justifyContent: 'space-between',
    },
    row3: {
        width: 380,
        height: 20,
        fontSize: 14,
        paddingLeft: 5,
        paddingRight: 5,
        paddingBottom: 20,
        marginTop: 10,
        justifyContent: 'space-between',
        alignItems: 'center',
        display: 'flex',
        flexDirection: 'row',
    },
    rowColumn: {
        display: 'flex',
        flexDirection: 'column',
    },
    rowColumn2: {
        display: 'flex',
        flexDirection: 'column',
        alignItems: 'right',
    },
    green2: {
        backgroundColor: theme.palette.primary.main,
        minWidth: '30%',
        color: theme.palette.base.white,
        marginRight: theme.spacing(2.5),
    },
    orange: {
        backgroundColor: theme.palette.primary.yellow,
        color: theme.palette.base.white,
        minWidth: '50%',
    },
    red: {
        backgroundColor: theme.palette.primary.red,
        color: theme.palette.base.white,
        minWidth: '50%',
    },
    red2: {
        backgroundColor: theme.palette.primary.red,
        color: theme.palette.base.white,
        minWidth: '30%',
        marginRight: theme.spacing(2.5),
    },
    white: {
        backgroundColor: theme.palette.primary.white,
        color: theme.palette.base.black,
        minWidth: '50%',
    },
    blue: {
        backgroundColor: theme.palette.secondary.main,
        color: theme.palette.base.white,
        minWidth: '50%',
    },
    title: {
        textAlign: 'center',
        color: theme.palette.base[700],
        '& .MuiTypography-root': {
            lineHeight: '25px',
        },
    },
    label: {
        color: theme.palette.base[400],
        fontSize: 18,
        textAlign: 'center',
        marginLeft: 20
    },
    value: {
        color: theme.palette.base[700],
        fontSize: 18,
        textAlign: 'center',
        marginLeft: 20
    },
}));

const tableOptions = {
    headerStyle: {
        padding: '15px 8px 15px 34px',
        lineHeight: '12px',
        color: '#1B1B28',
        textAlign: 'center',
    },
    paging: false
};
const cellStyle = {
    paddingLeft: 4,
    paddingRight: 4,
    lineHeight: '19px',
    color: '#1B1B28',
};


function InfoBar(props) {
    const classes = useStyles();
    const {t} = useTranslation();
    const {
        infoBar,
        userUseCases,
        isLoading,
        handleInfoBarDetail,
        table,
        subjects
    } = props;
    
    const [actionInProgress, setActionInProgress] = useState(false);
    const [openWindow, setOpenWindow] = useState(false);
    const [infoBarWindow, setInfoBarWindow] = useState();

    const renderInfoBarState = (param) => {
        if (!param) {
            return <Tooltip title={t('INFO_BAR_INACTIVE')}><SecurityIcon color="error" style={{ fontSize: 40 }}/></Tooltip>
        } else {
            if ( param.active )
                return <Tooltip title={t('INFO_BAR_ACTIVE')}><CertificateIcon color="primary" style={{ fontSize: 40 }}/></Tooltip>
            else                
                return <Tooltip title={t('INFO_BAR_INACTIVE')}><SecurityIcon color="error" style={{ fontSize: 40 }}/></Tooltip>
        }
    }

    const columns = [
        {   title: t('STATE'),
            field: 'active',
            headerStyle: {textAlign: 'left', paddingLeft: 5},
            cellStyle: {
                ...cellStyle,
                align: 'center', paddingLeft: 5
            },
            render: (rowData) => (
                renderInfoBarState(rowData)
            )
        },
        {
            title: t('INFO_BAR_TITLE'),
            field: 'title',
            headerStyle: {textAlign: 'left', paddingLeft: 5},
            cellStyle: {
                ...cellStyle,
                align: 'left',
            },
            render: (rowData) => (
                rowData.title
            )
        },
        {
            title: t('INFO_BAR_CONTENT'),
            field: 'content',
            headerStyle: {textAlign: 'left', paddingLeft: 5},
            cellStyle: {
                ...cellStyle,
                align: 'left',
            },
            render: (rowData) => (
                <div dangerouslySetInnerHTML={
                    {
                        __html: rowData.content
                    }
                }>
                </div>
            ),
        },
        {
            title: t('SUBJECT'),
            field: 'subject',
            headerStyle: {textAlign: 'left', paddingLeft: 5},
            cellStyle: {
                ...cellStyle,
                align: 'left',
            },
            render: (rowData) => (
                subjects.map((item)=>{
                    if ( item.subjectId==rowData.subjectId ) return item.name;
                }) 
            )
        },
        {
            title: t('FROM'),
            field: 'dateFrom',
            headerStyle: {textAlign: 'left', paddingLeft: 5},
            cellStyle: {
                ...cellStyle,
                align: 'left',
            },
            render: (rowData) => (
                moment(rowData.dateFrom).format("MM.DD.YYYY hh:mm:ss")
            ),
        },
        {
            title: t('TO'),
            field: 'dateTo',
            headerStyle: {textAlign: 'left', paddingLeft: 5},
            cellStyle: {
                ...cellStyle,
                align: 'left',
            },
            render: (rowData) => (
                moment(rowData.dateTo).format("MM.DD.YYYY hh:mm:ss")
            ),
        },
        {
            title: '',
            field: 'action',
            sorting: false,
            cellStyle: {
                ...cellStyle,
                padding: '12px 4px',
            },
            headerStyle: {
                padding: '18px 34px',
            },
            render: (rowData) => (
                <div className={classes.actions}>
                    {isComponentVisibleForUser(UC_INFO_BAR_DETAIL, userUseCases) &&
                        <Tooltip title={t('SHOW_INFO_BAR_DETAIL')}>
                            <ActionButton 
                                handleClick={() => {handleInfoBarDetail(rowData, false, true)}}
                                disabled={!isComponentEnabledForUser(UC_INFO_BAR_DETAIL, userUseCases) || actionInProgress}
                                className={classes.green2}
                                action="detail"
                            />
                        </Tooltip>
                    }
                    {isComponentVisibleForUser(UC_INFO_BAR_UPDATE, userUseCases) &&
                        <Tooltip title={t('SHOW_USER_EDIT')}>
                            <ActionButton 
                                handleClick={() => {handleInfoBarDetail(rowData, false, false)}}
                                disabled={!isComponentEnabledForUser(UC_INFO_BAR_UPDATE, userUseCases) || actionInProgress}
                                className={classes.red2}
                                action="edit"
                            />
                        </Tooltip>
                    }
                </div>
            ),
        },

    ];

    function HTMLTooltip(tooltip) {
        return <div dangerouslySetInnerHTML={
            {
                __html: tooltip
            }
        }>
        </div>;
    
    }

    const handleCloseWindow = () => {
        setOpenWindow(false);
    }

    return (
        <div className={classes.container}>
            {!isLoading ?
                table ? <CustomTable
                    title=""
                    columns={columns}
                    data={infoBar}
                    options={tableOptions}
                    isLoading={isLoading}
                    loadAllData={false}
                />:
                infoBar.map((infobarItem, index) => (
                    <div className={classes.parkingCard} key={'infoBar_'+index}>

                        <div className={classes.row1}>
                            <div className={classes.state}>
                                {renderInfoBarState(infobarItem)}
                            </div>
                            <div className={classes.title}>
                                <Tooltip title={HTMLTooltip(infobarItem.content)}>
                                    <div>
                                    <Typography variant="h4">{infobarItem.title}</Typography>
                                    <Typography variant="h4">{subjects.map((item)=>{if (item.subjectId==infobarItem.subjectId) return item.name})}</Typography>
                                    </div>
                                </Tooltip>
                            </div>
                            <div className={classes.actionIcon}>
                                {isComponentVisibleForUser(UC_INFO_BAR_UPDATE, userUseCases) &&
                                <Tooltip title={t('EDIT')}>
                                    <IconButton onClick={() => {
                                            handleInfoBarDetail(infobarItem, false, false)
                                        }}
                                        disabled={!isComponentEnabledForUser(UC_INFO_BAR_UPDATE, userUseCases)}>
                                        <EditIcon color="disabled" style={{ fontSize: 30 }}/>
                                    </IconButton>
                                </Tooltip>
                                }
                            </div>
                        </div>
                        <div className={classes.row3}>
                            <div className={classes.rowColumn}>
                                <div className={classes.label}>{t('FROM')}</div>
                                
                                <div className={classes.value}>{infobarItem.dateFrom ? moment(infobarItem.dateFrom).format('DD.MM.YYYY HH:mm') : '' }</div>
                            </div>
                            <div className={classes.rowColumn2}>
                            </div>
                            <div className={classes.rowColumn}>
                                <div className={classes.label}>{t('TO')}</div>
                                <div className={classes.value}>{infobarItem.dateTo ? moment(infobarItem.dateTo).format('DD.MM.YYYY HH:mm') : '' }</div>
                            </div>
                        </div>
                        <BottomNavigation
                            className={classes.root}
                        >
                            {isComponentVisibleForUser(UC_INFO_BAR_DETAIL, userUseCases) &&
                                <Tooltip title={t('SHOW_INFO_BAR_DETAIL')}>
                                    <BottomNavigationAction onClick={() => {handleInfoBarDetail(infobarItem, false, true)}}
                                        disabled={actionInProgress || !isComponentEnabledForUser(UC_INFO_BAR_DETAIL, userUseCases)}
                                        className={classes.blue}
                                        label="Detail"
                                        icon={<InfoIcon className={classes.actionIcon}/>}
                                    />
                                </Tooltip>
                            }
                            {isComponentVisibleForUser(UC_INFO_BAR_WINDOW, userUseCases) &&
                                <Tooltip title={t('SHOW_INFO_BAR_PREVIEW')}>
                                    <BottomNavigationAction onClick={() => {
                                        let tmp = [];
                                        tmp.push(infobarItem);
                                        setInfoBarWindow(tmp);
                                        setOpenWindow(true);
                                    }}
                                        disabled={actionInProgress || !isComponentEnabledForUser(UC_INFO_BAR_WINDOW, userUseCases)}
                                        className={classes.orange}
                                        label="Detail"
                                        icon={<WindowIcon className={classes.actionIcon}/>}
                                    />
                                </Tooltip>
                            }
                        </BottomNavigation>
                    </div>
                ))
                :
                <Loading/>
            }

            <InfoWindow
                infoBar={infoBarWindow}
                handleClose={handleCloseWindow}
                open={openWindow}
            />
        </div>
    );
}
InfoBar.propTypes = {
    parkings: PropTypes.array.isRequired,
};

const UC_INFO_BAR_WINDOW = 'UC0684';
const UC_INFO_BAR_DETAIL = 'UC0683';
const UC_INFO_BAR_UPDATE = 'UC0682';

InfoBar.defaultProps = {};
const mapStateToProps = (store) => ({});

export default connect(
    mapStateToProps,
)(InfoBar);