import React, {useEffect, useState} from 'react';
import {bindActionCreators} from 'redux';
import {connect} from 'react-redux';
import {useTranslation} from 'react-i18next';
import {makeStyles} from '@material-ui/core/styles';
import {isComponentEnabledForUser, isComponentVisibleForUser} from 'utils';
import ToolBar from './ToolBar';
import Typography from "@material-ui/core/Typography";
import classnames from 'classnames';
import {Link} from 'react-router-dom';
import PerfectScrollbar from "react-perfect-scrollbar";
import InfoBar from "./InfoBar";
import {fetchInfoBars, createInfoBar, updateInfoBar} from "../../../redux/actions/infoBars";
import {fetchAllSubjects} from "../../../redux/actions/subjects";
import {Grid} from '@material-ui/core';
import {ToggleButton} from '@material-ui/lab';
import TocIcon from '@material-ui/icons/Toc';
import InfoBarModal from 'components/modals/InfoBar';


const useStyles = makeStyles((theme) => ({
    breadcrumb: {
        display: 'flex',
        alignItems: 'center',
        fontWeight: 300,
        color: theme.palette.base[500],
    },
    title: {
        marginBottom: theme.spacing(3.75),
        marginTop: theme.spacing(3.75),
        textTransform: 'uppercase',
    },
    separator: {
        marginLeft: theme.spacing(1.5),
        marginRight: theme.spacing(1.5),
    },
    activeLink: {
        color: theme.palette.secondary.main,
    },
    navLink: {
        cursor: 'pointer',
    },
    container: {
        background: theme.palette.base.white,
        marginTop: theme.spacing(4),
    },
}));


function InfoBarAdministration(props) {
    const {
        userUseCases,
        infoBars, fetchInfoBars, createInfoBar, updateInfoBar,
        subjects, fetchAllSubjects,
    } = props;


    const classes = useStyles();
    const {t} = useTranslation();

    const [isFetchedInfoBars, setIsFetchedInfoBars] = useState(false);
    const [isFetchedSubjects, setIsFetchedSubjects] = useState(false);
    const [isLoading, setIsLoading] = useState(false);
    const [saving, setSaving] = useState(false);
    const [infoBarConfigModel, setInfoBarConfigModel] = useState({
        open: false,
        infoBar: {active:'true'},
        isNew: true
    });

    useEffect(() => {
        if (!isFetchedInfoBars) {
            setIsLoading(true);
            fetchInfoBars().then(()=>{
                setIsFetchedInfoBars(true)         
                setIsLoading(false);
            }).catch((err) => {
                console.log(err);
                setIsFetchedInfoBars(false);
            });
        }
        if (!isFetchedSubjects) {
            setIsLoading(true);
            fetchAllSubjects().then(()=>{
                setIsFetchedSubjects(true);
                setIsLoading(false);
            }).catch((err) => {
                console.log(err);
                setIsFetchedSubjects(false);
            });
        }
    }, [fetchInfoBars, isFetchedInfoBars, fetchAllSubjects, isFetchedSubjects]);


    const [selectedName, setSelectedName] = useState('');
    const [selectedStatus, setSelectedStatus] = useState();
    const [table, setTable] = useState(false);

    const handleSelectedName = (value) => {
        setSelectedName(value.target.value);
    };
    const handleSelectedStatus = (value) => {
        setSelectedStatus(value);
    };

    const matchStatus = (infoBar) => {
        let res = false;
        for (let i = 0; i < selectedStatus.length; i++) {
            res = ( selectedStatus[i].key === 'ACTIVE' && infoBar.active===true ) || ( selectedStatus[i].key === 'INACTIVE' && infoBar.active===false );
            if (res) {
                return true;
            }
        }
        return false;
    }

    useEffect(() => {
        const filInfoBar = [];
        if ( infoBars ) {
            for (let i = 0; i < infoBars.length; i++) {
                let matchCriteria = true;
                if (selectedName && selectedName.trim().length > 0) {
                    if ( !infoBars[i].title || !infoBars[i].title.toLowerCase().includes(selectedName.toLowerCase()) ) {
                        matchCriteria = false;
                    }
                }
                if (selectedStatus && selectedStatus.length > 0) {
                    const res = matchStatus(infoBars[i]);
                    if (!res) {
                        matchCriteria = false;
                    }
                }
                if (matchCriteria) {
                    filInfoBar.push(infoBars[i]);
                }
            }
        }
        setFilteredInfoBar(filInfoBar);
    }, [infoBars, selectedName, selectedStatus]);


    const [filteredInfoBar, setFilteredInfoBar] = useState([]);
    const [confirmModalOpened, setConfirmModalOpened] = useState(false);

    const closeInfoBarDetail = () => {
        setInfoBarConfigModel({isNew: false, infoBar: {}, open: false, readOnly: false});
    }

    const handleInfoBarUpdate = (updatedInfoBar, isNew, readOnly) => {
        setInfoBarConfigModel({isNew: isNew, infoBar: updatedInfoBar, open: true, readOnly: readOnly});
    };
   
    const handleSaveInfoBar = (infoBar) => {
        let result = {
            infoBarId: infoBar.infoBarId, title: infoBar.title, 
            content: infoBar.content, active: infoBar.active,
            dateFrom: infoBar.dateFrom, dateTo: infoBar.dateTo,
            subjectId: infoBar.subjectId,
            branchId: infoBar.branchId,
            showPermanent: infoBar.showPermanent,
            show: infoBar.show,
        }
        if ( infoBarConfigModel.isNew ) {
            createInfoBar(result)
            .then(()=>{
                setIsFetchedInfoBars(false);
                setSaving(false);
                closeInfoBarDetail();
            })
        } else {
            updateInfoBar(result)
            .then(()=>{
                setIsFetchedInfoBars(false);
                setSaving(false);
                closeInfoBarDetail();
            })
        }
    }

    return (
        <PerfectScrollbar
            options={{
                suppressScrollX: true,
                minScrollbarLength: 50,
            }}
        >

            <div className={classes.breadcrumb}>
                {isComponentVisibleForUser(UC_ADMINISTRATION, userUseCases) &&

                    <Link style={{textDecoration: 'none'}}
                          to={isComponentEnabledForUser(UC_ADMINISTRATION, userUseCases) ? `/${process.env.REACT_APP_PATH}/administration` : "#"}>

                        <Typography variant="button" className={classnames(classes.activeLink, classes.navLink)}>
                            {t('ADMINISTRATION')}
                        </Typography>
                    </Link>
                }
            </div>

            <Typography variant="h4" className={classes.title}>
                {t('INFO_BAR')}
            </Typography>


            <div className={classes.container}>
                <ToolBar
                    userUseCases={userUseCases}
                    handleInfoBarUpdate={handleInfoBarUpdate}

                    selectedName={selectedName}
                    handleSelectedName={handleSelectedName}
                    selectedStatus={selectedStatus}
                    handleSelectedStatus={handleSelectedStatus}
                />
                <Grid container>
                    <Grid item sm={11}>
                    </Grid>
                    <Grid item sm={1}>
                        <ToggleButton
                            style={{width: '30px', height: '30px'}}
                            value="check"
                            selected={table}
                            onChange={() => {
                                setTable(!table);
                            }}
                        >
                            <TocIcon/>
                        </ToggleButton>
                    </Grid>
                </Grid>
                <InfoBar
                    refresh={() => {setIsFetchedInfoBars(false);}}
                    table={table}
                    save={handleSaveInfoBar}
                    userUseCases={userUseCases}
                    infoBar={filteredInfoBar}
                    handleInfoBarDetail={handleInfoBarUpdate}
                    isLoading={isLoading}
                    confirmModalOpened={confirmModalOpened}
                    setConfirmModalOpened={setConfirmModalOpened}
                    subjects={subjects}
                />

            </div>

            <InfoBarModal
                infoBarConfigModel={infoBarConfigModel}
                userUseCases={userUseCases}
                saveInfoBar={handleSaveInfoBar}
                handleClose={closeInfoBarDetail}
                saving={saving}
                setSaving={setSaving}
                subjects={subjects}
            />
        </PerfectScrollbar>
    );
}

const UC_ADMINISTRATION = 'UC0600';

const mapStateToProps = (store) => ({
    infoBars: store.infoBarData.infoBars,
    subjects: store.subjectsData.subjects
});

const mapDispatchToProps = (dispatch) => bindActionCreators({
    fetchInfoBars, createInfoBar, updateInfoBar, fetchAllSubjects
}, dispatch);

export default connect(
    mapStateToProps,
    mapDispatchToProps,
)(InfoBarAdministration);
