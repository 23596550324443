import React from 'react';
import {bindActionCreators} from 'redux';
import {connect} from 'react-redux';
import {makeStyles} from '@material-ui/core/styles';
import classnames from 'classnames';
import Dialog from "@material-ui/core/Dialog";
import DialogTitle from "@material-ui/core/DialogTitle";
import DialogContent from "@material-ui/core/DialogContent/DialogContent";
import DialogActions from '@material-ui/core/DialogActions';
import {ActionButton} from 'components/common';

const useStyles = makeStyles((theme) => ({
    container: {
        '& .MuiDialog-paper': {
            maxHeight: 355,
            minHeight: '60%',
            maxWidth: '60%',
            // boxShadow: theme.palette.shadow.main,
        },
        height: '100%',
    },
    actions: {
        padding: 0,
    },
    title: {
        height: 96,
        background: theme.palette.secondary.main,

        '& h2': {
            height: '100%',
            display: 'flex',
            alignItems: 'center',
            justifyContent: 'space-between',
            fontSize: 26,
            lineHeight: '30px',
            color: theme.palette.base.white,
        },
    },
    content: {
        display: 'flex',
        flexDirection: 'column',
        width: '100%',
        height: '100%',
        margin: 0,
        paddingBottom: theme.spacing(4),

    },
    button: {
        width: 180,
        height: 40,
        marginLeft: 20,
        marginRight: 20,
        fontSize: 14,
    },
    buttonNavigation: {
        display: 'flex',
        background: theme.palette.base.white,
        height: '55px',
        marginTop: theme.spacing(2),
        marginBottom: theme.spacing(2),
        bottom: 0,
        width: '50%',
        justifyContent: 'flex-end',
    },
    wrapper: {
        margin: theme.spacing(1),
        position: 'relative',
    },
}));

function InfoWindow(props) {
    const {
        handleClose,
        infoBar,
        open
} = props;


    const classes = useStyles();

    return (
        <Dialog
            className={classes.container}
            infoBar={infoBar}
            open={open}
            onClose={handleClose}
        >
            { infoBar && infoBar.length > 0 && infoBar.map((item)=>{
                return <>
                    <DialogTitle className={classnames(classes.title)}>
                        <span>
                            {item ? item.title : ''}
                        </span>
                    </DialogTitle>

                    <DialogContent className={classes.content}>
                        <div dangerouslySetInnerHTML={
                                {
                                    __html: item ? item.content : ''
                                }
                            }>
                        </div>
                    </DialogContent>
                </>
            })}

            <DialogActions className={classes.actions}>
                <div className={classes.buttonNavigation}>
                    <div className={classes.wrapper}>
                        <ActionButton
                            action="close"
                            handleClick={handleClose}
                            className={classes.button}
                        />
                    </div>
                </div>
            </DialogActions>
        </Dialog>

    );
}

const mapStateToProps = (store) => ({
});

const mapDispatchToProps = (dispatch) => bindActionCreators({
}, dispatch);

export default connect(
    mapStateToProps,
    mapDispatchToProps,
)(InfoWindow);


