import React, {useEffect, useRef, useState} from 'react';
import {useTranslation} from 'react-i18next';
import {makeStyles} from '@material-ui/core/styles';

import Box from "@material-ui/core/Box";
import Paper from "@material-ui/core/Paper";
import Grid from "@material-ui/core/Grid";
import TextField from "@material-ui/core/TextField";
import Select from '@material-ui/core/Select';
import InputLabel from '@material-ui/core/InputLabel';
import MenuItem from '@material-ui/core/MenuItem';
import FormControl from '@material-ui/core/FormControl';

import Dialog from "@material-ui/core/Dialog";
import DialogTitle from "@material-ui/core/DialogTitle";
import DialogContent from "@material-ui/core/DialogContent/DialogContent";
import DialogActions from '@material-ui/core/DialogActions';

import {CustomTable} from 'components/elements';
import {bindActionCreators} from 'redux';
import {connect} from 'react-redux';
import {ActionButton} from "../../common";
import i18next from "i18next";
import moment from "moment";
import {MuiPickersUtilsProvider} from "@material-ui/pickers";
import MomentUtils from "@date-io/moment";
import {getLocale} from "../../../i18n";
import {searchUsers} from 'redux/actions/users';
import DateRangePicker from "../../elements/KeyboardDateTimePicker";
import Button from "@material-ui/core/Button";
import Typography from "@material-ui/core/Typography";
import Calendar from '@material-ui/icons/CalendarToday';
import {filterZReports, zReportDetail, zReportCreate, zReportPrint} from 'redux/actions/reports';
import Print from "@material-ui/icons/Print";
import {getErrorMessage, isComponentEnabledForUser, isComponentVisibleForUser} from "../../../utils";
import IconButton from "@material-ui/core/IconButton/IconButton";
import Add from "@material-ui/icons/Add";
import PMCSnackbar from "../../common/Snackbar";


const useStyles = makeStyles((theme) => ({
    container: {
        '& .MuiDialog-paper': {
            maxHeight: 855,
            minHeight: '90%',
            maxWidth: 'unset',
            // boxShadow: theme.palette.shadow.main,
        },
        height: '100%',
    },
    title: {
        height: 96,
        background: theme.palette.secondary.main,

        '& h2': {
            height: '100%',
            display: 'flex',
            alignItems: 'center',
            justifyContent: 'space-between',
            fontSize: 26,
            lineHeight: '30px',
            color: theme.palette.base.white,
        },
    },
    content: {
        background: theme.palette.base[200],
        height: '100%',
        width: '100%',
    },
    actions: {
        padding: 0,
    },
    buttonNavigation: {
        display: 'flex',
        background: theme.palette.base.white,
        height: '50px',
        padding: theme.spacing(1, 1, 1, 1),
        width: '100%',
        justifyContent: 'space-between',
    },
    buttonNavigationCancel: {
        display: 'flex',
        background: theme.palette.base.white,
        height: '50px',
        marginTop: theme.spacing(2),
        marginBottom: theme.spacing(2),
        bottom: 0,
        width: '100%',
        justifyContent: 'left',
    },
    button: {
        width: 200,
        marginLeft: 20,
        marginRight: 20,
    },
    buttonReceiptActions: {
        width: 30,
    },
    table: {
        width: '100%',
        flexDirection: 'column',
        display: 'flex',
        padding: theme.spacing(1, 1, 1, 1),
        justifyContent: 'space-between',
    },
    addButton: {
        backgroundColor: theme.palette.base[100],
        marginLeft: theme.spacing(10),
        padding: theme.spacing(2.5, 2.5, 2.5, 2.5),
    },
    addIcon: {
        width: 30,
        height: 30,
    },
}));


const defaultRange = {
    'dateFrom': moment(new Date())
        .subtract(50, "days")
        .toDate(), 'dateTo': new Date()
};

const ZReportsFilter = ({searchedUsers, onFilterChange, userUseCases, zReportCreate, ...props}) => {
    const [values, setValues] = useState({...defaultRange});
    const {t} = useTranslation();
    const classes = useStyles();

    const timeoutRef = useRef(null);

    const [isModalOpen, setIsModalOpen] = useState(false);

    const handleAccept = (startDate, endDate) => {
        setIsModalOpen(false);
        const newValues = {...values, 'dateFrom': startDate, 'dateTo': endDate};
        setValues(newValues);

        if (timeoutRef.current) {
            clearTimeout(timeoutRef.current);
        }
        timeoutRef.current = setTimeout(() => {
            onFilterChange && onFilterChange(newValues);
        }, 1000);
    };

    const handleCancel = () => {
        setIsModalOpen(false);
    };


    const handleChange = (e) => {
        const newValues = {...values, [e.target.name]: e.target.value};
        setValues(newValues);

        if (timeoutRef.current) {
            clearTimeout(timeoutRef.current);
        }
        timeoutRef.current = setTimeout(() => {
            onFilterChange && onFilterChange(newValues);
        }, 1000);
    };

    useEffect(() => () => {
        if (timeoutRef.current) {
            clearTimeout(timeoutRef.current);
        }
    }, []);

    const newZReport = () => {
        zReportCreate(localStorage.getItem('pos_id')).then(
            () => {
                onFilterChange(values);
            }
        );
    };

    return (
        <Paper>
            <Box p={1}>
                <Grid container spacing={1}>
                    <Grid item md>
                        <TextField
                            value={values.reportNumber}
                            name="reportNumber"
                            onChange={handleChange}
                            label={t("REPORT_NUMBER")}/>
                    </Grid>
                    <Grid item md>
                        <FormControl fullWidth>
                            <InputLabel>{t("CREATED_BY")}</InputLabel>
                            <Select
                                value={values.userId || ""}
                                name="userId"
                                onChange={handleChange}
                            >
                                <MenuItem value="">-</MenuItem>
                                {Array.isArray(searchedUsers) ? searchedUsers.map((item, key) => (
                                    <MenuItem key={item.userId} value={item.userId}>{item.email}</MenuItem>)) : null}
                            </Select>
                        </FormControl>
                    </Grid>
                    <Grid item md>
                        <MuiPickersUtilsProvider utils={MomentUtils} locale={getLocale(i18next.language)}>
                            <Box
                                display="flex"
                                alignItems="center"
                                justifyContent="center"
                            >
                                <Box>
                                    {values.dateFrom &&
                                    <Typography
                                        variant="caption"
                                        style={{display: "block", textAlign: "center"}}
                                    >
                                        {moment(values.dateFrom).format("MMM Do, YYYY")}
                                    </Typography>
                                    }
                                    <Button
                                        size="small"
                                        variant="outlined"
                                        endIcon={<Calendar/>}
                                        onClick={() => setIsModalOpen(true)} style={{
                                        width: "100%",
                                        height: 'auto',
                                        color: 'rgba(0, 0, 0, 0.54)',
                                        fontSize: '14px',
                                        marginTop: '5px',
                                        marginBottom: '5px',

                                    }}>
                                        {t("CREATION_DATE")}
                                    </Button>
                                    {values.dateTo &&
                                    <Typography
                                        variant="caption"
                                        style={{display: "block", textAlign: "center"}}
                                    >
                                        {moment(values.dateTo).format("MMM Do, YYYY")}
                                    </Typography>
                                    }
                                </Box>
                            </Box>
                            <DateRangePicker
                                title="Change date"
                                initialDateRange={{startDate: values.dateFrom, endDate: values.dateTo}}
                                open={isModalOpen}
                                onAccept={handleAccept}
                                onCancel={handleCancel}
                            />
                        </MuiPickersUtilsProvider>

                    </Grid>
                    <Grid item md>
                        {isComponentVisibleForUser(UC_ZREPORT_NEW, userUseCases) && (
                            <IconButton
                                onClick={() => {
                                    newZReport();
                                }}
                                className={classes.addButton}
                                edge="start"
                                color="inherit"
                                aria-label="new Z report"
                                disabled={!isComponentEnabledForUser(UC_ZREPORT_NEW, userUseCases)}>
                                <Add className={classes.addIcon}/>
                            </IconButton>)}

                    </Grid>
                </Grid>
            </Box>
        </Paper>
    );
};

const cellStyle = {
    padding: '4px 4px',
    lineHeight: '19px',
    color: '#1B1B28',
    textAlign: 'center',
};

const ZReports = ({
                      open, handleClose, filterZReports,
                      zReports, isFetchingList, searchUsers,
                      searchedUsers, userUseCases, zReportDetail, zReport,
                      zReportCreate, zReportPrint,
                  }) => {
    const classes = useStyles();

    const {t} = useTranslation();

    const [size, setSize] = useState(5);
    const [page, setPage] = useState(0);
    const resetPagination = () => {
        setPage(0);
    };

    const handleChangeRowsPerPage = (value) => {
        resetPagination();
        setSize(value);
    };
    const handleChangePage = (value) => {
        setPage(value);
    };

    const refreshFilter = (filter, lpage, lsize) => {
        return filterZReports(filter, lpage || page, lsize || size);
    };

    useEffect(() => {
        if (!!open) {
            refreshFilter({...defaultRange}).then(() => {
                searchUsers().then()
            });
        }
    }, [open]);


    const [mode, setMode] = useState('COMMON');
    const [selectedReport, setSelectedReport] = useState();

    const [snackbarOpen, setSnackbarOpen] = useState(false);
    const [message, setMessage] = useState({});


    const handleCloseSnackbar = (event, reason) => {
        if (reason === 'clickaway') {
            return;
        }

        setSnackbarOpen(false);
    };


    const print = (zReport) => {

        zReportPrint(localStorage.getItem('pos_id'), zReport.zreportId).then(
            () => {
                setSnackbarOpen(true);
                setMessage({message: "Report was sent to the printer", severity: "success"});
            }
        ).catch((error) => {
            setSnackbarOpen(true);
            setMessage({
                message: getErrorMessage(error, t),
                severity: 'error'
            });
        });
    }


    let columns = [
        {
            title: t('REPORT_NUMBER'),
            field: 'number',
            cellStyle,
            render: (rowData) => (
                rowData.number
            ),
        },
        {
            title: t('CREATION_DATE'), field: 'owner', cellStyle, render: (rowData) => (
                <>
                    {rowData.date
                        ? moment(new Date(rowData.date)).locale(i18next.language).format('L LTS')
                        : ''}
                </>
            ),
        },
        {
            title: t('RECEIPT_COUNT'),
            field: 'pos',
            cellStyle,
            render: (rowData) => (
                rowData.receiptCount
            ),
        },
        {
            title: t('CREATED_BY'),
            field: 'userId',
            cellStyle,
            render: (rowData) => (
                rowData.user
            ),
        },
        {
            title: '',
            field: 'print',
            cellStyle,
            render: (rowData) => (
                <Print color="primary" onClick={(event) => {
                    event.stopPropagation();
                    print(rowData);
                }}
                />),

        },
    ];


    if (mode === 'COMMON') {

        return (
            <
                Dialog
                open={open}
                onClose={handleClose}
                className={classes.container}
            >
                < DialogTitle
                    className={classes.title}>
            <span>
            {t('CASHDESK_TOOL_Z_REPORT')}
            </span>
                </DialogTitle>
                <DialogContent className={classes.content}>
                    {/* Filter */}
                    <Box my={2}>
                        <ZReportsFilter onFilterChange={refreshFilter} userUseCases={userUseCases}
                                        searchedUsers={searchedUsers} zReportCreate={zReportCreate}
                        />
                    </Box>

                    <PMCSnackbar open={snackbarOpen} onClose={handleCloseSnackbar} severity={message.severity}
                                 message={message.message}/>


                    <Grid container spacing={2} style={{flex: 1, height: '80%'}}>
                        <Grid item md={12}>
                            <Paper className={classes.table}>
                                <CustomTable
                                    title=""
                                    columns={columns}
                                    data={zReports.zreports}
                                    options={{
                                        headerStyle: {
                                            padding: '0 8px 0 34px',
                                            borderTop: '1px solid #DEE2E5',
                                            lineHeight: '12px',
                                            color: '#1B1B28',
                                            textAlign: 'center',
                                        },
                                        rowStyle: rowData => ({
                                            backgroundColor: (selectedReport && selectedReport.tableData && selectedReport.tableData.id === selectedReport.tableData.id) ? '#5985EE' : '#FFF'
                                        })
                                    }}
                                    isLoading={isFetchingList}
                                    handleChangeRowsPerPage={handleChangeRowsPerPage}
                                    handleChangePage={handleChangePage}
                                    rowsPerPage={size}
                                    pageNumber={page}
                                    count={zReports && zReports.zreports ? zReports.zreports.length : 0}
                                    loadAllData
                                    onRowClick={((evt, selectedReport) => {
                                        setSelectedReport(selectedReport);
                                        zReportDetail(localStorage.getItem('pos_id'), selectedReport.zreportId).then(
                                            () => {
                                                setMode('REPORT');
                                            }
                                        )
                                    })}
                                />
                            </Paper>
                        </Grid>
                    </Grid>

                </DialogContent>
                <DialogActions className={classes.actions}>
                    <div className={classes.buttonNavigationCancel}>

                        <ActionButton
                            action="close"
                            handleClick={handleClose}
                            className={classes.button}
                        />
                    </div>

                </DialogActions>
            </Dialog>

        )
    }
    return (
        <Dialog
            open={open}
            onClose={handleClose}
            className={classes.container}
        >
            < DialogTitle
                className={classes.title}>
            <span>
            {`${t('CASHDESK_TOOL_Z_REPORT')} - ${selectedReport ? selectedReport.number : ''}`}
            </span>
            </DialogTitle>
            <DialogContent className={classes.content}>

                <div dangerouslySetInnerHTML={{__html: zReport && zReport.html}}
                     style={{width: '100%', height: '80%'}}>

                </div>

            </DialogContent>
            <DialogActions className={classes.actions}>
                <div className={classes.buttonNavigationCancel}>

                    <ActionButton
                        action="close"
                        handleClick={() => {
                            setMode('COMMON')
                        }}
                        className={classes.button}
                    />
                </div>

            </DialogActions>
        </Dialog>
    );

};

const mapStateToProps = (store) => ({
    zReports: store.reportsData.zReports,
    zReport: store.reportsData.zReport,
    isFetchingList: store.reportsData.isFetchingList,
    searchedUsers: store.usersData.searchedUsers,
});

const UC_ZREPORT_NEW = 'UC0509';


const mapDispatchToProps = (dispatch) => bindActionCreators({
    filterZReports,
    searchUsers,
    zReportDetail,
    zReportPrint,
    zReportCreate,
}, dispatch);

export default connect(
    mapStateToProps,
    mapDispatchToProps,
)(ZReports);
