import React, {useEffect, useState} from 'react';
import {useTranslation} from 'react-i18next';
import {makeStyles} from '@material-ui/core/styles';
import {connect, useSelector} from 'react-redux';
import {bindActionCreators} from 'redux';

import Grid from "@material-ui/core/Grid";
import TextField from "@material-ui/core/TextField";
import FormHelperText from "@material-ui/core/FormHelperText";
import FormControl from "@material-ui/core/FormControl";
import InputLabel from "@material-ui/core/InputLabel";
import Select from "@material-ui/core/Select";
import MenuItem from "@material-ui/core/MenuItem";
import {FormControlLabel, InputAdornment, LinearProgress, Tooltip, Typography} from '@material-ui/core';
import PerfectScrollbar from "react-perfect-scrollbar";
import { CustomSwitch, Loading } from 'components/elements';
import RichTextEditor from 'react-rte';
import CustomDateTimePicker from "../../elements/CustomDateTimePicker";
import {fetchBranchesBySubject} from "redux/actions/branches";
import { isAdmin } from 'utils';
import { Info } from '@material-ui/icons';

const toolbarConfig = {
    // Optionally specify the groups to display (displayed in the order listed).
    display: ['INLINE_STYLE_BUTTONS', 'BLOCK_TYPE_BUTTONS', 'LINK_BUTTONS', 'BLOCK_TYPE_DROPDOWN', 'HISTORY_BUTTONS'],
    INLINE_STYLE_BUTTONS: [
        {label: 'Bold', style: 'BOLD', className: 'custom-css-class'},
        {label: 'Italic', style: 'ITALIC'},
        {label: 'Underline', style: 'UNDERLINE'}
    ],
    BLOCK_TYPE_DROPDOWN: [
        {label: 'Normal', style: 'unstyled'},
        {label: 'Heading Large', style: 'header-one'},
        {label: 'Heading Medium', style: 'header-two'},
        {label: 'Heading Small', style: 'header-three'}
    ],
    BLOCK_TYPE_BUTTONS: [
        {label: 'UL', style: 'unordered-list-item'},
        {label: 'OL', style: 'ordered-list-item'}
    ]
};

const useStyles = makeStyles((theme) => ({
    box: {
        flexGrow: 1,
        height: '100%',
        width: '100%',
        border: `1px solid ${theme.palette.base[400]}`,
        // marginBottom: '20px',
        padding: '20px'
    },
    item: {
        height: '490px',
        border: `1px solid ${theme.palette.base[400]}`,
        marginTop: '-10px',
        padding: '20px'
    },
    errorMessage: {
        fontSize: 13,
        color: theme.palette.primary.red,
    },
    myDropZone: {
        minHeight: '130px',
    },
    shortName: {
        height: 24,
        fontSize: 16,
        marginLeft: 3,
        marginRight: 3,
        width: '100%',
        textAlign: 'center',
        textOverflow: 'ellipsis',
        overflow: 'hidden',
        whiteSpace: 'nowrap',
    },
    container: {
        width: '100%',
        display: 'flex',
        flexWrap: 'wrap'
    },
    video: {
        width: '150px',
        height: '150px',
        margin: '20px'
    },
    iconChoosed: {
        marginLeft: '-20px',
        color: '#6BBBAE'
    },
    break: {
        flexBasis: '100%',
        height: 0,
    },
    parkingNameMargin: {
        marginTop: '20px',
    },
    editor: {
        height: '100%',
        width: '100%',
        marginTop: '4px',
    },
}));

function InfoBarView(props) {
    const classes = useStyles();

    const {
        handleChange,
        updatedInfoBar,
        htmlContent,
        error,
        readOnly,
        subjects,
        branches,
        loading,
        saving,
        isFetchedBranches,
        setIsFetchedBranches,
        fetchBranchesBySubject,
    } = props;

    const {t} = useTranslation();

    useEffect(() => {
        if (!isFetchedBranches && updatedInfoBar && updatedInfoBar.subjectId) {
            fetchBranchesBySubject(updatedInfoBar.subjectId).then(()=>{
                setIsFetchedBranches(true);
            }).catch((err) => {
                console.log(err);
                setIsFetchedBranches(false);
            });
        }
    }, [fetchBranchesBySubject, isFetchedBranches, updatedInfoBar]);

    const user = useSelector((state) => state.authData.user);

    return (
        <div className={classes.box}>
            {loading &&
                <Loading />
            }
            { !loading && updatedInfoBar &&
                <Grid container spacing={2}>
                    <Grid item xs={4} >
                        <TextField
                            style={{height: '60px'}}
                            autoFocus
                            helperText={error && error.title
                                ?
                                <span className={classes.errorMessage}>{t('THIS_FIELD_IS_REQUIRED')}</span>
                                : null}
                            fullWidth
                            disabled={readOnly}
                            id="title"
                            value={updatedInfoBar.title || ''}
                            onChange={handleChange('title', false)}
                            label={t('INFO_BAR_TITLE')}/>
                    </Grid>
                    <Grid item xs={2} >
                        <FormControl required fullWidth disabled={false} style={{height: '60px'}}>
                            <InputLabel id="active-label">
                                {t('ACTIVE')}
                            </InputLabel>
                            <Select
                                autoWidth
                                disabled={readOnly}
                                value={updatedInfoBar.active || ''}
                                id="active"
                                onChange={handleChange('active', true)}
                            >
                                <MenuItem value=''>-</MenuItem>
                                <MenuItem value={'true'}>{t('YES')}</MenuItem>
                                <MenuItem value={'false'}>{t('NO')}</MenuItem>
                            </Select>
                            <FormHelperText>  {error && error.active && (
                                <span className={classes.errorMessage}>{t('THIS_FIELD_IS_REQUIRED')}</span>
                            )}</FormHelperText>
                        </FormControl>
                    </Grid>
                    <Grid item xs={2}>
                        <CustomDateTimePicker
                            pure={true}
                            disabled={readOnly}
                            required={false}
                            handleChange={handleChange('dateFrom')}
                            fullWidth={true}
                            value={updatedInfoBar && updatedInfoBar.dateFrom || null}
                            label={t('VALID_FROM')}
                        >
                        </CustomDateTimePicker>
                    </Grid>
                    <Grid item xs={2}>
                        <CustomDateTimePicker
                            pure={true}
                            disabled={readOnly}
                            required={false}
                            handleChange={handleChange('dateTo')}
                            fullWidth={true}
                            value={updatedInfoBar && updatedInfoBar.dateTo || null}
                            label={t('VALID_TO')}
                        >
                        </CustomDateTimePicker>
                    </Grid>
                    <Grid item xs={2} />
                    <Grid item xs={2} >
                        <FormControl fullWidth required={!isAdmin(user)}>
                            <InputLabel id="subject-label">
                                {t('SUBJECT')}
                            </InputLabel>
                            <Select
                                disabled={readOnly}
                                autoWidth
                                value={updatedInfoBar && updatedInfoBar.subjectId || ''}
                                id="subject"
                                onChange={handleChange('subjectId')}
                            >
                                {subjects && subjects.map(a => {
                                    return (
                                        <MenuItem value={a.subjectId}>{a.name}</MenuItem>
                                    )
                                })
                                }
                            </Select>
                        </FormControl>
                        { !isAdmin(user) && 
                            <FormHelperText>  
                                {error && error.subjectId && (
                                    <span className={classes.errorMessage}>{t('THIS_FIELD_IS_REQUIRED')}</span>
                                )}
                            </FormHelperText>
                        }
                    </Grid>
                    <Grid item xs={2} >
                        <FormControl fullWidth>
                            <InputLabel id="branch-label">
                                {t('BRANCH')}
                            </InputLabel>
                            <Select
                                disabled={readOnly}
                                autoWidth
                                value={updatedInfoBar && updatedInfoBar.branchId || ''}
                                id="branch"
                                onChange={handleChange('branchId')}
                            >
                                {branches && branches.map(a => {
                                    return (
                                        <MenuItem value={a.id}>{a.branchName}</MenuItem>
                                    )
                                })
                                }
                            </Select>
                        </FormControl>
                    </Grid>
                    <Grid itemx xs={1}>
                        <FormControlLabel
                            classes={{
                                labelPlacementTop: classes.labelPlacementTop,
                                root: classes.root,
                            }}
                            control={<CustomSwitch
                                disabled={readOnly}
                                checked={updatedInfoBar.showPermanent || false}
                                color="secondary"
                                handleSwitch={handleChange('showPermanent')}/>
                            }
                            label={<span
                                style={{
                                    fontWeight: 400,
                                    fontSize: '14px',
                                    color: 'rgba(0, 0, 0, 0.54)',
                                }}>{t('INFO_BAR_SHOW_PERMANENT')}</span>}
                            labelPlacement="top"
                        />
                    </Grid>
                    <Grid item xs={1} >
                        <TextField
                            fullWidth id="show"
                            disabled={readOnly || updatedInfoBar.showPermanent}
                            type='number'
                            label={t('INFO_BAR_SHOW')}
                            value={updatedInfoBar && updatedInfoBar.show || 0}
                            onChange={handleChange('show')}
                        />
                    </Grid>
                    <Grid item xs={2} >

                    </Grid>
                    <Grid item xs={4} />
                    <Grid item xs={12} >
                        <Typography variant='h6'>{t('INFO_BAR_CONTENT')}</Typography>
                    </Grid>
                    <Grid item xs={12} >
                        <div className={classes.item}>
                            <PerfectScrollbar
                                options={{
                                    suppressScrollX: true,
                                    minScrollbarLength: 50,
                                }}
                            >
                                <div>
                                    { htmlContent &&
                                        <RichTextEditor
                                            toolbarConfig={toolbarConfig}
                                            value={htmlContent}
                                            onChange={handleChange('content')}
                                            className={classes.editor}
                                            disabled={readOnly}
                                            height={'100%'}
                                        />
                                    }
                                </div>
                            </PerfectScrollbar>
                        </div>
                    </Grid>
                    {saving &&
                        <Grid item xs={12}>
                            <LinearProgress color="secondary" />
                        </Grid>
                    }
                </Grid>
            }
        </div>
    )
}

InfoBarView.propTypes = {};
InfoBarView.defaultProps = {};


const mapStateToProps = (store) => ({
    branches: store.branchesData.branchesBySubject,
});
const mapDispatchToProps = (dispatch) => bindActionCreators({
    fetchBranchesBySubject
}, dispatch);

export default connect(
    mapStateToProps,
    mapDispatchToProps,
)(InfoBarView);


