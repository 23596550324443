import React, {useState} from 'react';
import {useTranslation} from 'react-i18next';
import {connect} from 'react-redux';
import PropTypes from 'prop-types';
import {makeStyles} from '@material-ui/core/styles';
import {CustomTabs} from 'components/elements';
import MainCashDesk from "./MainCashDesk";
import CashDeskTools from "./CashdeskTools";
import {isComponentVisibleForUser} from "../../utils";


const useStyles = makeStyles((theme) => ({
    container: {
        background: theme.palette.base.white,
        height: '100%',
        width: '100%',
    },
}));


const CashdeskMainContent = ({...props}) => {
    const classes = useStyles();
    const {t} = useTranslation();
    const [activeTab, setActiveTab] = useState(0);
    const handleChangeTab = (value) => {
        setActiveTab(value);
    };

    const {userUseCases} = props;
    const checkActivated = (value) => value === activeTab;
    const tabs = [];

    const tabContents = [];

    if (isComponentVisibleForUser(UC_MENU_CASHDESK_DASHBOARD, userUseCases)) {
        tabs.push(t('CASHDESK_TAB_CASHDESK'));
        tabContents.push(<MainCashDesk cashDeskActiveTab={activeTab} userUseCases={props.userUseCases}/>);

    };

    if (isComponentVisibleForUser(UC_MENU_CASHDESK_TOOL, userUseCases)) {
        tabs.push(t('CASHDESK_TAB_TOOLS'));
        tabContents.push(<CashDeskTools cashDeskActiveTab={activeTab} userUseCases={props.userUseCases}/>);
    };

    return (
        <div className={classes.container}>
            <CustomTabs
                tabs={tabs}
                tabContents={tabContents}
                variant="fullWidth"
                handleChangeTab={handleChangeTab}
            />
        </div>
    );
}


CashdeskMainContent.propTypes = {
    user: PropTypes.object,
};

CashdeskMainContent.defaultProps = {
    user: null,

};

const mapStateToProps = (store) => ({});

const UC_MENU_CASHDESK_DASHBOARD = 'UC0402';
const UC_MENU_CASHDESK_TOOL = 'UC0500';


export default connect(
    mapStateToProps,
)(CashdeskMainContent);
