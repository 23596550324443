import types from '../actionTypes';

const INITIAL_STATE = {
  isFetchingInfoBar : false,
  isFetchingInfoBarDetail: false,
  isCreating: false,
  isUpdating: false,
  infoBars: [],
  infoBar: {},
  errors: '',
};

export default (state = INITIAL_STATE, action) => {
  switch (action.type) {
    case types.INFOBAR_FETCH_REQUEST:
      return {
        ...state,
        isFetchingInfoBar: true,
      };
    case types.INFOBAR_FETCH_SUCCESS:
      return {
        ...state,
        isFetchingInfoBar: false,
        infoBars: action.payload.infoBars,
      };
    case types.INFOBAR_FETCH_FAILT:
      return {
        ...state,
        isFetchingInfoBar: false,
        errors: action.payload.error,
      };
    case types.SET_INFOBAR:
        return {
          ...state,
          infoBar: action.payload.infoBar,
        };
    default:
      return state;
  }
};
