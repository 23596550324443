import React, {useState} from 'react';
import {useTranslation} from 'react-i18next';
import {makeStyles} from '@material-ui/core/styles';
import {connect} from 'react-redux';
import {bindActionCreators} from 'redux';
import {isComponentEnabledForUser, isComponentVisibleForUser} from "../../../utils";
import Typography from "@material-ui/core/Typography";
import Visibility from "@material-ui/icons/Visibility";
import VisibilityOff from "@material-ui/icons/VisibilityOff";

import IconButton from "@material-ui/core/IconButton";
import {CustomCircleChart, Loading} from 'components/elements';
import {updateZoneParkingVisibility} from "../../../redux/actions/parkings";


const useStyles = makeStyles((theme) => ({
    container: {
        width: '100%',
        display: 'flex',
        flexWrap: 'wrap',
    },
    zoneCard: {
        width: 250,
        height: 250,
        margin: theme.spacing(2.5),
        display: 'flex',
        flexDirection: 'column',
        justifyContent: 'space-between',
        background: theme.palette.base[100],
        borderStyle: 'solid',
        borderWidth: '2px',
        borderColor: theme.palette.base[200],
        boxShadow: '10px 10px 8px #EEEEEE',
    },
    row1: {
        width: '100%',
        height: 10,
        fontSize: 12,
        padding: 5,
        marginTop: 10,
        display: 'flex',
        flexDirection: 'row',
        alignItems: 'center',
        justifyContent: 'space-between',
    },
    row2: {
        width: '100%',
        paddingLeft: 5,
        paddingRight: 5,
        paddingBottom: 5,
        justifyContent: 'center',
        alignItems: 'center',
        display: 'flex',
        flexDirection: 'row',
        height: '100%',
    },
    row2column: {
        paddingLeft: '5px',
        width: '50%',
        justifyContent: 'center',
        alignItems: 'flex-start',
        display: 'flex',
        flexDirection: 'column',
        height: '100%',
    },
    rowColumn: {
        display: 'flex',
        flexDirection: 'column',
        padding: '5px',
    },
    title: {
        color: theme.palette.base[700],

        '& .MuiTypography-root': {
            fontSize: 20,
        },
    },
    label: {
        color: theme.palette.base[400],
        fontSize: 16,
    },
    value: {
        color: theme.palette.base[700],
        fontSize: 18,
    },
    showOff: {
        color: theme.palette.base[400],
    },
    showOn: {
        color: theme.palette.secondary.main,
    },
    circleChart: {
        fontWeight: 800,
        '& .apexcharts-datalabel-label': {
            fontWeight: 'bold',
        },
    },
}))


function WizardStep2(props) {
    const classes = useStyles();

    const {
        parking,
        error,
        readOnly,
        userUseCases,
        updateZoneParkingVisibility,
        isLoading
    } = props;

    const {t} = useTranslation();

    const [zoneToBeUpdated, setZoneToBeUpdated] = useState();


    const handleShowZone = (zone) => {
        setZoneToBeUpdated(zone);
        updateZoneParkingVisibility(parking.parkingId, zone.zoneId, !zone.show).then(
            () => {
                setZoneToBeUpdated(null);
            }
        ).catch(() => {
            setZoneToBeUpdated(null);
        });
    }


    return (

        <div className={classes.container}>

            {parking && parking.zoneStatus &&  parking.zoneStatus.length > 0 &&
            parking.zoneStatus.map((zone, index) => (
                <div className={classes.zoneCard} key={`zone_${zone.zoneId}`}>
                    <div className={classes.row1}>
                        <div className={classes.title}>
                            <Typography variant="h3">{zone.zoneName}</Typography>
                        </div>
                    </div>
                    {(isLoading && zoneToBeUpdated && zoneToBeUpdated.zoneId === zone.zoneId) ? <Loading></Loading> :
                        <div className={classes.row2}>
                            <div className={classes.row2column}>
                                {isComponentVisibleForUser(UC_PARKING_ZONE_UPDATE, userUseCases) &&
                                <IconButton
                                    edge="start"
                                    color="inherit"
                                    aria-label="open edit"
                                    disabled={!isComponentEnabledForUser(UC_PARKING_ZONE_UPDATE, userUseCases)}
                                    onClick={() => {
                                        handleShowZone(zone)
                                    }}
                                >
                                    {zone.show ? <Visibility className={classes.showOn}/> :
                                        <VisibilityOff className={classes.showOff}/>
                                    }
                                </IconButton>
                                }

                                <div className={classes.circleChart}>
                                    <CustomCircleChart
                                        height={200}
                                        series={[zone.capacity === null ? 0 : (zone.capacity > zone.occupancy
                                            ? Math.floor((zone.occupancy * 100) / zone.capacity)
                                            : 100)]}
                                        label={t('OCCUPANCY')}
                                    />
                                </div>


                            </div>


                            <div className={classes.row2column}>

                                <div className={classes.rowColumn}>
                                    <div className={classes.label}>{t('CAPACITY')}</div>
                                    <div className={classes.value}>{zone.capacity}</div>
                                </div>

                                <div className={classes.rowColumn}>
                                    <div className={classes.label}>{t('RESERVED')}</div>
                                    <div className={classes.value}>{zone.reserved}</div>
                                </div>

                                <div className={classes.rowColumn}>
                                    <div className={classes.label}>{t('OCCUPANCY')}</div>
                                    <div className={classes.value}>{zone.occupancy}</div>
                                </div>

                            </div>

                        </div>}


                </div>
            ))}
        </div>)

}

const UC_PARKING_ZONE_UPDATE = 'UC0633';


WizardStep2.propTypes = {};

WizardStep2.defaultProps = {};

const mapStateToProps = (store) => ({
    isLoading: store.parkingsData.isUpdatingZoneVisibilityForParkingConfiguration,

});

const mapDispatchToProps = (dispatch) => bindActionCreators({
    updateZoneParkingVisibility,
}, dispatch);

export default connect(
    mapStateToProps,
    mapDispatchToProps,
)(WizardStep2);


