import CARD_CHECKER1 from 'assets/CARD_CHECKER-1.png';
import CARD_CHECKER2 from 'assets/CARD_CHECKER-2.png';
import CARD_CHECKER3 from 'assets/CARD_CHECKER-3.png';
import CHARGING_TERMINAL0 from 'assets/CHARGING_TERMINAL-0.png';
import CHARGING_TERMINAL1 from 'assets/CHARGING_TERMINAL-1.png';
import CHARGING_TERMINAL10 from 'assets/CHARGING_TERMINAL-10.png';
import CHARGING_TERMINAL11 from 'assets/CHARGING_TERMINAL-11.png';
import CHARGING_TERMINAL12 from 'assets/CHARGING_TERMINAL-12.png';
import CHARGING_TERMINAL2 from 'assets/CHARGING_TERMINAL-2.png';
import CHARGING_TERMINAL4 from 'assets/CHARGING_TERMINAL-4.png';
import CHARGING_TERMINAL20 from 'assets/CHARGING_TERMINAL-20.png';
import CHARGING_TERMINAL21 from 'assets/CHARGING_TERMINAL-21.png';
import CHARGING_TERMINAL22 from 'assets/CHARGING_TERMINAL-22.png';
import DOOR_TERMINAL1 from 'assets/DOOR_TERMINAL-1.png';
import DOOR_TERMINAL2 from 'assets/DOOR_TERMINAL-2.png';
import DOOR_TERMINAL3 from 'assets/DOOR_TERMINAL-3.png';
import DOOR_TERMINAL4 from 'assets/DOOR_TERMINAL-4.png';
import DOOR_TERMINAL5 from 'assets/DOOR_TERMINAL-5.png';
// eslint-disable-next-line camelcase
import DOOR_TERMINALbad from 'assets/DOOR_TERMINAL-bad.png';
// eslint-disable-next-line camelcase
import DOOR_TERMINALok from 'assets/DOOR_TERMINAL-ok.png';
// eslint-disable-next-line camelcase
import DOOR_TERMINALpermo from 'assets/DOOR_TERMINAL-permo.png';
// eslint-disable-next-line camelcase
import DOOR_TERMINALpermo2 from 'assets/DOOR_TERMINAL-permo_2.png';
// eslint-disable-next-line camelcase
import DOOR_TERMINALrun from 'assets/DOOR_TERMINAL-run.png';
// eslint-disable-next-line camelcase
import DISPLAY_CONTROL_UNIT1 from 'assets/DISPLAY_CONTROL_UNIT-1.png';
import DISPLAY_CONTROL_UNIT2 from 'assets/DISPLAY_CONTROL_UNIT-2.png';
//eslint-disable-next-line camelcase
import DISPLAY_CONTROL_UNITbad from 'assets/DISPLAY_CONTROL_UNIT-bad.png';
// eslint-disable-next-line camelcase
import MiPIA_STATION1 from 'assets/MiPIA_STATION-1.png';
// eslint-disable-next-line camelcase
import MiPIA_STATION2 from 'assets/MiPIA_STATION-2.png';
// eslint-disable-next-line camelcase
import MiPIA_STATION3 from 'assets/MiPIA_STATION-3.png';
// eslint-disable-next-line camelcase
import MiPIA_STATIONbad from 'assets/MiPIA_STATION-bad.png';
// eslint-disable-next-line camelcase
import MiPIA_STATIONok from 'assets/MiPIA_STATION-ok.png';
// eslint-disable-next-line camelcase
import MiPIA_STATIONrun from 'assets/MiPIA_STATION-run.png';
import PARK_TERMINAL1 from 'assets/PARK_TERMINAL-1.png';
import PARK_TERMINAL2 from 'assets/PARK_TERMINAL-2.png';
import PARK_TERMINAL3 from 'assets/PARK_TERMINAL-3.png';
import PARK_TERMINAL4 from 'assets/PARK_TERMINAL-4.png';
import PARK_TERMINAL5 from 'assets/PARK_TERMINAL-5.png';
import PARK_TERMINAL6 from 'assets/PARK_TERMINAL-6.png';
import PARK_TERMINAL7 from 'assets/PARK_TERMINAL-7.png';
import PARK_TERMINAL8 from 'assets/PARK_TERMINAL-8.png';
import PARK_TERMINAL9 from 'assets/PARK_TERMINAL-9.png';
import PARK_TERMINAL10 from 'assets/PARK_TERMINAL-10.png';
import PARK_TERMINAL11 from 'assets/PARK_TERMINAL-11.png';
import PARK_TERMINAL12 from 'assets/PARK_TERMINAL-12.png';
import PARK_TERMINAL13 from 'assets/PARK_TERMINAL-13.png';
import PARK_TERMINAL14 from 'assets/PARK_TERMINAL-14.png';
import PARK_TERMINAL15 from 'assets/PARK_TERMINAL-15.png';
import PARK_TERMINAL16 from 'assets/PARK_TERMINAL-16.png';
import PARK_TERMINAL17 from 'assets/PARK_TERMINAL-17.png';
import PARK_TERMINAL18 from 'assets/PARK_TERMINAL-18.png';
import PARK_TERMINAL21 from 'assets/PARK_TERMINAL-21.png';
import PARK_TERMINAL22 from 'assets/PARK_TERMINAL-22.png';
import PARK_TERMINAL23 from 'assets/PARK_TERMINAL-23.png';
import PARK_TERMINAL24 from 'assets/PARK_TERMINAL-24.png';
import PARK_TERMINAL25 from 'assets/PARK_TERMINAL-25.png';
import PARK_TERMINAL26 from 'assets/PARK_TERMINAL-26.png';
import DOOR_TERMINAL27 from 'assets/PARK_TERMINAL-27.png';
import PARK_TERMINAL28 from 'assets/PARK_TERMINAL-28.png';
import PARK_TERMINAL31 from 'assets/PARK_TERMINAL-31.png';
import PARK_TERMINAL32 from 'assets/PARK_TERMINAL-32.png';
import PARK_TERMINAL33 from 'assets/PARK_TERMINAL-33.png';
import PARK_TERMINAL34 from 'assets/PARK_TERMINAL-34.png';
import PARK_TERMINAL35 from 'assets/PARK_TERMINAL-35.png';
import PARK_TERMINAL36 from 'assets/PARK_TERMINAL-36.png';
import PARK_TERMINAL37 from 'assets/PARK_TERMINAL-37.png';
import PARK_TERMINAL38 from 'assets/PARK_TERMINAL-38.png';
import PARK_TERMINAL101 from 'assets/PARK_TERMINAL-101.png';
import PARK_TERMINAL102 from 'assets/PARK_TERMINAL-102.png';
import PARK_TERMINAL104 from 'assets/PARK_TERMINAL-104.png';
import PARK_TERMINAL105 from 'assets/PARK_TERMINAL-105.png';
import PARK_TERMINAL121 from 'assets/PARK_TERMINAL-121.png';
import PARK_TERMINAL122 from 'assets/PARK_TERMINAL-122.png';
import PARK_TERMINAL123 from 'assets/PARK_TERMINAL-123.png';
import PARK_TERMINAL124 from 'assets/PARK_TERMINAL-124.png';
import PARK_TERMINAL125 from 'assets/PARK_TERMINAL-125.png';
import PARK_TERMINAL126 from 'assets/PARK_TERMINAL-126.png';
import PARK_TERMINAL201 from 'assets/PARK_TERMINAL-201.png';
import PARK_TERMINAL202 from 'assets/PARK_TERMINAL-202.png';
import PARK_TERMINAL205 from 'assets/PARK_TERMINAL-205.png';
import PARK_TERMINAL206 from 'assets/PARK_TERMINAL-206.png';
import PARK_TERMINAL211 from 'assets/PARK_TERMINAL-211.png';
import PARK_TERMINAL212 from 'assets/PARK_TERMINAL-212.png';
import PARK_TERMINAL401 from 'assets/PARK_TERMINAL-401.png';
import PARK_TERMINAL402 from 'assets/PARK_TERMINAL-402.png';
import PARK_TERMINAL405 from 'assets/PARK_TERMINAL-405.png';
import PARK_TERMINAL406 from 'assets/PARK_TERMINAL-406.png';
import PARK_TERMINAL425 from 'assets/PARK_TERMINAL-425.png';
import PARK_TERMINAL426 from 'assets/PARK_TERMINAL-426.png';
import PARK_TERMINAL502 from 'assets/PARK_TERMINAL-502.png';
import PARK_TERMINAL505 from 'assets/PARK_TERMINAL-505.png';
import PARK_TERMINAL506 from 'assets/PARK_TERMINAL-506.png';
import PARK_TERMINAL525 from 'assets/PARK_TERMINAL-525.png';
import PARK_TERMINAL526 from 'assets/PARK_TERMINAL-526.png';
// eslint-disable-next-line camelcase
import PARK_TERMINALbad from 'assets/PARK_TERMINAL-bad.png';
// eslint-disable-next-line camelcase
import PARK_TERMINALdummy from 'assets/PARK_TERMINAL-dummy.png';
// eslint-disable-next-line camelcase
import PARK_TERMINAL1dummy2 from 'assets/PARK_TERMINAL-dummy_2.png';
// eslint-disable-next-line camelcase
import PARK_TERMINALfreeze from 'assets/PARK_TERMINAL-freeze.png';
// eslint-disable-next-line camelcase
import PARK_TERMINALfreezev2 from 'assets/PARK_TERMINAL-freeze-v2.png';
// eslint-disable-next-line camelcase
import PARK_TERMINALok from 'assets/PARK_TERMINAL-ok.png';
// eslint-disable-next-line camelcase
import PARK_TERMINALpermo from 'assets/PARK_TERMINAL-permo.png';
// eslint-disable-next-line camelcase
import PARK_TERMINALpermo2 from 'assets/PARK_TERMINAL-permo_2.png';
// eslint-disable-next-line camelcase
import PARK_TERMINALrun from 'assets/PARK_TERMINAL-run.png';
import PASS_TERMINAL1 from 'assets/PASS_TERMINAL-1.png';
import PASS_TERMINAL2 from 'assets/PASS_TERMINAL-2.png';
import PASS_TERMINAL3 from 'assets/PASS_TERMINAL-3.png';
import PASS_TERMINAL4 from 'assets/PASS_TERMINAL-4.png';
import PASS_TERMINAL5 from 'assets/PASS_TERMINAL-5.png';
// eslint-disable-next-line camelcase
import PASS_TERMINALbad from 'assets/PASS_TERMINAL-bad.png';
// eslint-disable-next-line camelcase
import PASS_TERMINALok from 'assets/PASS_TERMINAL-ok.png';
// eslint-disable-next-line camelcase
import PASS_TERMINALrun from 'assets/PASS_TERMINAL-run.png';
import PAY_STATION1 from 'assets/PAY_STATION-1.png';
import PAY_STATION2 from 'assets/PAY_STATION-2.png';
import PAY_STATION3 from 'assets/PAY_STATION-3.png';
import PAY_STATION4 from 'assets/PAY_STATION-4.png';
import PAY_STATION5 from 'assets/PAY_STATION-5.png';
import PAY_STATION6 from 'assets/PAY_STATION-6.png';
import PAY_STATION7 from 'assets/PAY_STATION-7.png';
import PAY_STATION8 from 'assets/PAY_STATION-8.png';
import PAY_STATION9 from 'assets/PAY_STATION-9.png';
import PAY_STATION10 from 'assets/PAY_STATION-10.png';
import PAY_STATION11 from 'assets/PAY_STATION-11.png';
import PAY_STATION12 from 'assets/PAY_STATION-12.png';
import PAY_STATION13 from 'assets/PAY_STATION-13.png';
import PAY_STATION14 from 'assets/PAY_STATION-14.png';
import PAY_STATION15 from 'assets/PAY_STATION-15.png';
import PAY_STATION16 from 'assets/PAY_STATION-16.png';
import PAY_STATION17 from 'assets/PAY_STATION-17.png';
import PAY_STATION18 from 'assets/PAY_STATION-18.png';
import PAY_STATION19 from 'assets/PAY_STATION-19.png';
import PAY_STATION20 from 'assets/PAY_STATION-20.png';
import PAY_STATION21 from 'assets/PAY_STATION-21.png';
// eslint-disable-next-line camelcase
import PAY_STATIONbad from 'assets/PAY_STATION-bad.png';
// eslint-disable-next-line camelcase
import PAY_STATIONok from 'assets/PAY_STATION-ok.png';
// eslint-disable-next-line camelcase
import PAY_STATIONrun from 'assets/PAY_STATION-run.png';
import NOT_FOUND from 'assets/not-found-icon-22.png';

export const icons = {
  CARD_CHECKER1,
  CARD_CHECKER2,
  CARD_CHECKER3,
  CHARGING_TERMINAL0,
  CHARGING_TERMINAL1,
  CHARGING_TERMINAL10,
  CHARGING_TERMINAL11,
  CHARGING_TERMINAL12,
  CHARGING_TERMINAL2,
  CHARGING_TERMINAL20,
  CHARGING_TERMINAL21,
  CHARGING_TERMINAL22,
  CHARGING_TERMINAL4,
  DOOR_TERMINAL1,
  DOOR_TERMINAL2,
  DOOR_TERMINAL3,
  DOOR_TERMINAL4,
  DOOR_TERMINAL5,
  DOOR_TERMINALbad,
  DOOR_TERMINALok,
  DOOR_TERMINALpermo,
  DOOR_TERMINALpermo2,
  DOOR_TERMINALrun,
  DISPLAY_CONTROL_UNIT1,
  DISPLAY_CONTROL_UNIT2,
  DISPLAY_CONTROL_UNITbad,
  MiPIA_STATION1,
  MiPIA_STATION2,
  MiPIA_STATION3,
  MiPIA_STATIONbad,
  MiPIA_STATIONok,
  MiPIA_STATIONrun,
  PARK_TERMINAL1,
  PARK_TERMINAL2,
  PARK_TERMINAL3,
  PARK_TERMINAL4,
  PARK_TERMINAL5,
  PARK_TERMINAL6,
  PARK_TERMINAL7,
  PARK_TERMINAL8,
  PARK_TERMINAL9,
  PARK_TERMINAL10,
  PARK_TERMINAL11,
  PARK_TERMINAL12,
  PARK_TERMINAL13,
  PARK_TERMINAL14,
  PARK_TERMINAL15,
  PARK_TERMINAL16,
  PARK_TERMINAL17,
  PARK_TERMINAL18,
  PARK_TERMINAL21,
  PARK_TERMINAL22,
  PARK_TERMINAL23,
  PARK_TERMINAL24,
  PARK_TERMINAL25,
  PARK_TERMINAL26,
  DOOR_TERMINAL27,
  PARK_TERMINAL28,
  PARK_TERMINAL31,
  PARK_TERMINAL32,
  PARK_TERMINAL33,
  PARK_TERMINAL34,
  PARK_TERMINAL35,
  PARK_TERMINAL36,
  PARK_TERMINAL37,
  PARK_TERMINAL38,

  PARK_TERMINAL101,
  PARK_TERMINAL102,
  PARK_TERMINAL104,
  PARK_TERMINAL105,
  PARK_TERMINAL121,
  PARK_TERMINAL122,
  PARK_TERMINAL123,
  PARK_TERMINAL124,
  PARK_TERMINAL125,
  PARK_TERMINAL126,

  PARK_TERMINAL201,
  PARK_TERMINAL202,
  PARK_TERMINAL205,
  PARK_TERMINAL206,
  PARK_TERMINAL211,
  PARK_TERMINAL212,

  PARK_TERMINAL401,
  PARK_TERMINAL402,
  PARK_TERMINAL405,
  PARK_TERMINAL406,
  PARK_TERMINAL425,
  PARK_TERMINAL426,
  PARK_TERMINAL502,
  PARK_TERMINAL505,
  PARK_TERMINAL506,
  PARK_TERMINAL525,
  PARK_TERMINAL526,
  PARK_TERMINALbad,
  PARK_TERMINALdummy,
  PARK_TERMINAL1dummy2,
  PARK_TERMINALfreeze,
  PARK_TERMINALfreezev2,
  PARK_TERMINALok,
  PARK_TERMINALpermo,
  PARK_TERMINALpermo2,
  PARK_TERMINALrun,
  PASS_TERMINAL1,
  PASS_TERMINAL2,
  PASS_TERMINAL3,
  PASS_TERMINAL4,
  PASS_TERMINAL5,
  PASS_TERMINALbad,
  PASS_TERMINALok,
  PASS_TERMINALrun,
  PAY_STATION1,
  PAY_STATION2,
  PAY_STATION3,
  PAY_STATION4,
  PAY_STATION5,
  PAY_STATION6,
  PAY_STATION7,
  PAY_STATION8,
  PAY_STATION9,
  PAY_STATION10,
  PAY_STATION11,
  PAY_STATION12,
  PAY_STATION13,
  PAY_STATION14,
  PAY_STATION15,
  PAY_STATION16,
  PAY_STATION17,
  PAY_STATION18,
  PAY_STATION19,
  PAY_STATION20,
  PAY_STATION21,
  PAY_STATIONbad,
  PAY_STATIONok,
  PAY_STATIONrun,
};

export const getIcon = (iconName) => {
  const iconNameNormalized = iconName.replace('-', '');
  // console.log("Normalized icon for "+iconName);
  // console.log("Returning "+(icons[iconNameNormalized] ? icons[iconNameNormalized] : NOT_FOUND));
  return icons[iconNameNormalized] ? icons[iconNameNormalized] : NOT_FOUND;
};

export const statuses = {
  Status_258: {
    name: 'Communication',
    status: 'error',
    color: 'red',
  },
  Status_259: {
    name: 'Communication',
    status: 'ok',
    color: 'green',
  },
  Status_275: {
    name: 'Printer',
    status: 'error',
    color: 'red',
  },
  Status_276: {
    name: 'Printer',
    status: 'paper low',
    color: 'yellow',
  },
  Status_277: {
    name: 'Printer',
    status: 'ok',
    color: 'green',
  },
  Status_279: {
    name: 'Barrier',
    status: 'error',
    color: 'red',
  },
  Status_280: {
    name: 'Barrier',
    status: 'ok',
    color: 'green',
  },
  Status_294: {
    name: 'Host',
    status: 'free',
    color: 'green',
  },
  Status_295: {
    name: 'Host',
    status: 'full',
    color: 'red',
  },
  Status_298: {
    name: 'Barrier - arm',
    status: 'open',
    color: 'green',
  },
  Status_299: {
    name: 'Barrier - arm',
    status: 'close',
    color: 'green',
  },
  Status_309: {
    name: 'Detector',
    status: 'active',
    color: 'green',
  },
  Status_310: {
    name: 'Detector',
    status: 'inactive',
    color: 'green',
  },
  Status_315: {
    name: 'Door',
    status: 'open',
    color: 'yellow',
  },
  Status_316: {
    name: 'Door',
    status: 'close',
    color: 'green',
  },
  Status_319: {
    name: 'Tube',
    status: 'low',
    color: 'yellow',
  },
  Status_320: {
    name: 'Tube',
    status: 'empty',
    color: 'red',
  },
  Status_321: {
    name: 'Tube',
    status: 'ok',
    color: 'green',
  },
  Status_323: {
    name: 'Cash box',
    status: 'ok',
    color: 'green',
  },
  Status_324: {
    name: 'Cash box',
    status: 'full',
    color: 'red',
  },
  Status_326: {
    name: 'Banknote checker',
    status: 'ok',
    color: 'green',
  },
  Status_327: {
    name: 'Banknote checker',
    status: 'error',
    color: 'red',
  },
  Status_331: {
    name: 'Paymachine',
    status: 'on',
    color: 'green',
  },
  Status_332: {
    name: 'Paymachine',
    status: 'out of order',
    color: 'red',
  },
  Status_335: {
    name: 'Note dispensor',
    status: 'ok',
    color: 'green',
  },
  Status_336: {
    name: 'Note dispensor',
    status: 'error',
    color: 'red',
  },
  Status_337: {
    name: 'Terminal',
    status: 'on',
    color: 'green',
  },
  Status_338: {
    name: 'Terminal',
    status: 'out of order',
    color: 'red',
  },
  Status_339: {
    name: 'Terminal',
    status: 'no issue',
    color: 'yellow',
  },
  Status_340: {
    name: 'Coin dispenser',
    status: 'ok',
    color: 'green',
  },
  Status_341: {
    name: 'Coin dispenser',
    status: 'error',
    color: 'red',
  },
  Status_342: {
    name: 'Terminal',
    status: 'freeze time',
    color: 'green',
  },
  Status_343: {
    name: 'Terminal',
    status: 'dummy',
    color: 'brown',
  },
  Status_346: {
    name: 'Socket1',
    status: 'free',
    color: 'green',
  },
  Status_347: {
    name: 'Socket1',
    status: 'in use',
    color: 'yellow',
  },
  Status_348: {
    name: 'Socket1',
    status: 'error',
    color: 'red',
  },
  Status_349: {
    name: 'Socket2',
    status: 'free',
    color: 'green',
  },
  Status_350: {
    name: 'Socket2',
    status: 'in use',
    color: 'yellow',
  },
  Status_351: {
    name: 'Socket2',
    status: 'error',
    color: 'red',
  },
  Status_352: {
    name: 'Terminal',
    status: 'dummy no issue',
    color: 'green',
  },
  Status_353: {
    name: 'Terminal',
    status: 'dummy freeze time',
    color: 'green',
  },
  Status_354: {
    name: 'Terminal',
    status: 'no issue freeze time',
    color: 'green',
  },
  Status_355: {
    name: 'Terminal',
    status: 'dummy no issue freeze time',
    color: 'green',
  },
  Status_356: {
    name: 'Reject vault',
    status: 'ok',
    color: 'green',
  },
  Status_357: {
    name: 'Reject vault',
    status: 'almost full',
    color: 'yellow',
  },
  Status_358: {
    name: 'Reject vault',
    status: 'full',
    color: 'red',
  },
  Status_400: {
    name: 'DisplayText',
    status: 'ok',
    color: 'green',
  },
};
const semiWidth = 185;
const fullWidth = 390;


export const getCommandProps = (action, t) => {
  switch (action) {
    case 'cmd_BtnInOrder':
      return {
        width: semiWidth,
        label: t('IN_ORDER'),
        color: 'green',
      };
    case 'cmd_BtnOpen':
      return {
        width: semiWidth,
        label: t('OPEN'),
        color: 'green',
      };
    case 'cmd_BtnRestart':
      return {
        width: semiWidth,
        label: t('RESTART'),
        color: 'green',
      };
    case 'cmd_BtnClose':
      return {
        width: semiWidth,
        label: t('CLOSE'),
        color: 'green',
      };
    case 'cmd_BtnOpenPerm':
      return {
        width: fullWidth,
        label: t('OPEN_PERMANENTLY'),
        color: 'green',
      };
    case 'cmd_BtnEnumeration':
      return {
        width: fullWidth,
        label: t('ENUME'),
        color: 'green',
      };
    case 'cmd_BtnDoorOpen':
      return {
        width: semiWidth,
        label: t('OPEN_DOOR'),
        color: 'green',
      };
    case 'cmd_BtnSetText':
      return {
        width: semiWidth,
        minWidth: '100%',
        label: t('SET_TEXT'),
        color: 'green',
        };
    case 'cmd_BtnSetFullText':
      return {
        width: semiWidth,
        minWidth: '100%',
        label: t('SET_FULL_TEXT'),
        color: 'green',
      };      
    case 'openBarrier':
      return {
        width: fullWidth,
        label: t('OPEN_BARRIER_IMMEDIATELY'),
        color: 'dark',
      };
    case 'callIntercom':
      return {
        width: fullWidth,
        label: t('CALL_INTERCOM'),
        color: 'green',
      };
    case 'createTask':
      return {
        width: fullWidth,
        label: t('CREATE_TASK_FOR_MOBILE_OPERATOR'),
        color: 'blue',
      };
    default:
      return {};
  }
};

export const getActionLabel = (action, t) => {
  switch (action) {
    case 'act_BtnOutOfOrder':
      return t('OUT_OF_ORDER');
    case 'act_BtnDisableIssue':
      return t('DISABLE_CARD_ISSUING');
    case 'act_FreezOrder':
      return t('FREEZE_TIME');
    case 'act_BtnAutoDisplay':
      return t('AUTOMATIC_DISPLAY_VALUES')
    case 'act_DummyMode':
      return t('DUMMY_MODE')
    default:
      return '';
  }
};
