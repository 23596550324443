import React, { useState } from 'react';
import classnames from 'classnames';
import i18next from 'i18next';
import moment from 'moment/min/moment-with-locales';
import TableCell from '@material-ui/core/TableCell';
import { makeStyles } from '@material-ui/core/styles';
import {AutoSizer, Column, Table} from 'react-virtualized';
import {useTranslation} from 'react-i18next';
import {ParkingName} from 'components/common';

import {getCardNumber, getEventDescription, isCardEvent, isComponentVisibleForUser, isComponentEnabledForUser} from 'utils';
import EventType from 'components/common/EventType';
import ActionButton from 'components/common/ActionButton';
import Typography from '@material-ui/core/Typography';
import PhotoCameraIcon from '@material-ui/icons/PhotoCamera';
import PhotoDetail from 'components/modals/PhotoDetail';

const useStyles = makeStyles((theme) => ({
    flexContainer: {
        display: 'flex',
        alignItems: 'center',
        boxSizing: 'border-box',
    },
    table: {
        // temporary right-to-left patch, waiting for
        // https://github.com/bvaughn/react-virtualized/issues/454
        '& .ReactVirtualized__Table__headerRow': {
            flip: false,
            paddingRight: theme.direction === 'rtl' ? '0 !important' : undefined,
        },
        '& .ReactVirtualized__Table__Grid': {
            '&:focus': {
                outline: 'none',
            }
        },
    },
    tableRow: {
        borderBottom: '1px solid rgba(224, 224, 224, 1)',
    },
    tableRowHover: {
        '&:hover': {
            backgroundColor: theme.palette.grey[200],
        },
    },
    tableCell: {
        flex: 1,
    },
    noClick: {
        cursor: 'initial',
    },
    hostLink: {
        textDecoration: 'none',
        borderBottom: '1px dotted grey',
        color: '#1B1B28',
        cursor: 'pointer',
    },
    pointer: {
        cursor: "pointer",
    },
}));


export default function OnlineEventsVirtualizedTable(props) {

    const classes = useStyles();

    const {t} = useTranslation();
    const {events, parkings, selectParking, handleHostNameClick, handleCardNameClick, handleCreateTask, simple, userUseCases} = props;
    const headerHeight = 58;
    const rowHeight = 58;

    const [showPhoto, setShowPhoto] = useState(false);
    const [rowData, setRowData] = useState(null);

    const hadlePhotoClick = (rowData) => () => {
        setShowPhoto(true);
        setRowData(rowData);
    }

    const columns = [
        {
            label: t('PARKING_NAME'),
            dataKey: 'parkingId',
            flexGrow: 1,
            width: 280,
            cellRenderer: (rowData) => {
                const parking = parkings.find((p) => p.parkingId === rowData.rowData.parkingId);
                return (
                    <div className={classes.wrapper}>
                        <ParkingName parking={parking}
                                     handleSymbolClick={selectParking(rowData.rowData.parkingId)}/>
                    </div>
                );
            },
        },
        {
            label: t('PARKING_HOST'),
            dataKey: 'parkingHost',
            flexGrow: 1,
            width: 130,
            cellRenderer: (rowData) => {
                return (<span
                    className={classes.hostLink}
                    title={t('OPEN_HOST_DETAIL')}
                    onClick={
                        (e) => {
                            e.preventDefault();
                            handleHostNameClick(rowData.rowData.parkingHost, rowData.rowData.parkingId);
                        }
                    }
                >
                         <Typography style={{fontWeight: 400}} variant="subtitle2">
                          {rowData.rowData.parkingHost}
                                              </Typography>

                          </span>)
            },
        },
        {
            label: t('LICENSE_PLATE'),
            dataKey: 'lpn',
            flexGrow: 1,
            width: 200,
            cellRenderer: (rowData) => {
                return (
                    <Typography  style={{fontWeight: 400}} variant="subtitle2">
                        {rowData.rowData.lpn}
                    </Typography>
                )
            },
        },
        {
            label: t('PHOTO'),
            dataKey: 'photo',
            flexGrow: 1,
            width: 50,
            cellRenderer: (rowData) =>  {
                return (
                    <>
                    {((rowData.rowData.photoKeyPic && rowData.rowData.photoKeyPic!=='0000000000000000') || (rowData.rowData.photoKeyLpn && rowData.rowData.photoKeyLpn!=='0000000000000000')) &&
                        <PhotoCameraIcon onClick={hadlePhotoClick(rowData.rowData)} className={classes.pointer}/>
                    }
                    </>
                )
            }
        },
        {
            label: t('DATE'),
            dataKey: 'date',
            flexGrow: 1,
            width: 230,
            cellRenderer: (rowData) => {
                return (
                    <Typography  style={{fontWeight: 400}} variant="subtitle2">
                        {rowData.rowData.date ? moment(new Date(rowData.rowData.date)).locale(i18next.language).format('L LTS') : ''}
                    </Typography>
                )
            },
        },
        {
            label: t('EVENT'),
            dataKey: 'detail',
            flexGrow: 1,
            width: 390,
            cellRenderer: (rowData) => {
                return (
                    isCardEvent(rowData.rowData.description)
                        ? (
                            <span
                                className={classes.hostLink}
                                title={t('OPEN_CARD_DETAIL')}
                                onClick={
                                    (e) => {
                                        e.preventDefault();
                                        handleCardNameClick(rowData.rowData.parkingId, getCardNumber(rowData.rowData.messageParam), rowData.rowData.parkingHost);
                                    }
                                }
                            >
              <Typography style={{fontWeight: 400}}
                          variant="subtitle2">{getEventDescription(t(rowData.rowData.message && rowData.rowData.message.startsWith('message.') ? rowData.rowData.message.substring(8, rowData.rowData.message.length) : rowData.rowData.message), rowData.rowData.messageParam, t)}    </Typography>
            </span>
                        )
                        : <Typography style={{fontWeight: 400}}
                                      variant="subtitle2">{getEventDescription(t(rowData.rowData.message && rowData.rowData.message.startsWith('message.') ? rowData.rowData.message.substring(8, rowData.rowData.message.length) : rowData.rowData.message), rowData.rowData.messageParam, t)}    </Typography>


                )
            },
        },
        {
            label: t('MESSAGE_TYPE'),
            dataKey: 'eventType',
            flexGrow: 1,
            width: 200,
            cellRenderer: (rowData) => {
                const descStrs = rowData.rowData.category.category.split('_');
                const type = descStrs[descStrs.length - 1].toLowerCase();
                return (
                    <EventType type={type} border chipColor={rowData.rowData.category.color}/>
                );
            },
        }
    ];

    if (isComponentVisibleForUser(UC_TASK_CREATE, userUseCases)) {
        columns.push({
            label: t('ACTION'),
            dataKey: 'action',
            flexGrow: 1,
            width: 200,
            cellRenderer: (rowData) => {
                return (<ActionButton
                    action="assign"
                    handleClick={
                        (rowData.rowData.action && rowData.rowData.action !== 'action')
                            ? null
                            : handleCreateTask(rowData.rowData)
                    }
                />);
            },
        });
    };

    const cellRenderer = ({cellData, columnIndex}) => {
        return (
            <TableCell
                component="div"
                className={classnames(classes.tableCell, classes.flexContainer)}
                variant="body"
                style={
                    {
                        paddingLeft: 4,
                        paddingRight: 4,
                        lineHeight: '19px',
                        color: '#1B1B28',
                        textAlign: 'center',
                    }
                }
            >
                {cellData}
            </TableCell>
        );
    };

    const headerRenderer = ({label, columnIndex}) => {
        return (
            <TableCell
                component="div"
                className={classnames(classes.tableCell, classes.flexContainer, classes.noClick)}
                variant="head"
                style={
                    {
                        padding: '15px 8px 15px 0px',
                        lineHeight: '12px',
                        color: '#1B1B28',
                        textAlign: 'center',
                    }

                }
            >
                <span>{label}</span>
            </TableCell>
        );
    };

    const getRowClassName = ({index}) => {
        if (index === -1) {
            return classnames(classes.flexContainer);
        }
        return classnames(classes.tableRow, classes.flexContainer);
    };

    return (
        <div style={{height: simple ? 1200 : 343, width: '100%'}}>
            <AutoSizer>
                {({height, width}) => (
                    <Table
                        height={height}
                        width={width}
                        rowHeight={rowHeight}
                        rowCount={events.length}
                        rowGetter={({index}) => events[index]}
                        headerHeight={headerHeight}
                        className={classes.table}
                        rowClassName={getRowClassName}
                    >
                        {columns.map(({dataKey, ...other}, index) => {
                            return (
                                <Column
                                    key={dataKey}
                                    style={{
                                        height: rowHeight,
                                        paddingLeft: 4,
                                        paddingRight: 4,
                                        lineHeight: '19px',
                                        color: '#1B1B28',
                                        textAlign: 'center',
                                    }}
                                    headerRenderer={(headerProps) =>
                                        headerRenderer({
                                            ...headerProps,
                                            columnIndex: index,
                                        })
                                    }
                                    className={classes.flexContainer}
                                    cellRenderer={cellRenderer}
                                    dataKey={dataKey}
                                    {...other}
                                />
                            );
                        })}
                    </Table>
                )}
            </AutoSizer>

            {showPhoto &&
                <PhotoDetail showPhoto={showPhoto} setShowPhoto={setShowPhoto} rowData={rowData} setRowData={setRowData}/>
            }
        </div>
    );
}

const UC_TASK_CREATE = 'UC0038';
