import httpService from './http.service';
import moment from "moment";


const getAllBranches = () => httpService
    .get('/branches')
    .then(({data}) => data)
    .catch((err) => Promise.reject(err.response));

const getBranchesBySubject = (subjcetId) => httpService
    .get('/branches/'+subjcetId)
    .then(({data}) => data)
    .catch((err) => Promise.reject(err.response));

const getQuery = (filter, page, size) => {
    let query = '?';
    if (filter) {
        for (let paramName in filter) {
            if (filter[paramName]) {
                query += `${paramName}=${encodeURIComponent((paramName === 'dateFrom' || paramName === 'dateTo') ?
                    moment(filter[paramName]).format('DD.MM.YYYY') : filter[paramName])}&`;
            }
        }
    }
    query += `page=${page}&size=${size}`;
    return query;
};


const getAllBranchesParam = (filter, page, size) => httpService
    .get(`/branches/param${getQuery(filter, page, size)}`)
    .then(({data}) => data)
    .catch((err) => Promise.reject(err.response));


const createBranch = (subject) => httpService
    .post(`/branch`, subject)
    .then(({data}) => data)
    .catch((err) => {
        throw err;
    });

const updateBranch = (subject) => httpService
    .put(`/branch`, subject)
    .then(({data}) => data)
    .catch((err) => Promise.reject(err));




export default {
    getAllBranches,
    updateBranch,
    createBranch,
    getAllBranchesParam,
    getBranchesBySubject
};
