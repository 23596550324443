import React, {useEffect, useState} from 'react';
import {bindActionCreators} from 'redux';
import {connect, useDispatch} from 'react-redux';
import {useTranslation} from 'react-i18next';
import {makeStyles} from '@material-ui/core/styles';
import classnames from 'classnames';
import Dialog from "@material-ui/core/Dialog";
import DialogTitle from "@material-ui/core/DialogTitle";
import DialogContent from "@material-ui/core/DialogContent/DialogContent";
import DialogActions from '@material-ui/core/DialogActions';
import {ActionButton} from 'components/common';
import PMCSnackbar from "../../common/Snackbar";
import moment from 'moment/min/moment-with-locales';
import i18next from 'i18next';
import { getPhotoDetail } from 'redux/actions/photo';
import PropTypes from 'prop-types';
import types from 'redux/actionTypes';

const useStyles = makeStyles((theme) => ({
    container: {
        '& .MuiDialog-paper': {
            maxHeight: "100%nvp"
        },
        height: '100%',
    },
    actions: {
        padding: 0,
    },
    title: {
        height: 60,
        background: theme.palette.primary.main,

        '& h2': {
            height: '100%',
            display: 'flex',
            alignItems: 'center',
            justifyContent: 'space-between',
            fontSize: 26,
            lineHeight: '30px',
            color: theme.palette.base.white,
        },
    },
    content: {
        display: 'flex',
        flexDirection: 'column',
        width: '100%',
        height: '100%',
        margin: 0,
        paddingBottom: theme.spacing(4),

    },
    content2: {
        alignItems: 'center'
    },
    button: {
        width: 180,
        height: 40,
        marginLeft: 20,
        marginRight: 20,
        fontSize: 14,
    },
    buttonNavigation: {
        display: 'flex',
        background: theme.palette.base.white,
        height: '55px',
        marginTop: theme.spacing(2),
        marginBottom: theme.spacing(2),
        bottom: 0,
        width: '50%',
        justifyContent: 'flex-end',
    },
    buttonNavigationCancel: {
        display: 'flex',
        background: theme.palette.base.white,
        height: '50px',
        marginTop: theme.spacing(2),
        marginBottom: theme.spacing(2),
        bottom: 0,
        width: '100%',
        justifyContent: 'center',
    },
}));


function PhotoDetail(props) {
    const {
        showPhoto, setShowPhoto, rowData, setRowData, getPhotoDetail, photoData
    } = props;

    const classes = useStyles();
    const {t} = useTranslation();
    const dispatch = useDispatch();

    const handleClose = () => {
        setShowPhoto(false);
        setRowData(null);
        dispatch({
            type: types.PHOTODETAIL_FETCH_SUCCESS,
            payload: {photo:''},
        });
    }

    const [snackbarOpen, setSnackbarOpen] = useState();

    const handleCloseSnackbar = (event, reason) => {
        if (reason === 'clickaway') {
            return;
        }

        setSnackbarOpen(false);
    };

    const [pos, setPos] = useState({ x: 0, y: 0, scale: 1 });

    const onScroll = (e) => {
        const delta = e.deltaY * -0.001;
        const newScale = pos.scale + delta;
    
        const ratio = 1 - newScale / pos.scale;
    
        setPos({
          scale: newScale,
          x: pos.x,
          y: pos.y,
        //   x: pos.x + (e.clientX - pos.x) * ratio,
        //   y: pos.y + (e.clientY - pos.y) * ratio
        });
      };

    useEffect(() => {
        if ( rowData ) {
            getPhotoDetail(rowData.photoKeyPic?rowData.photoKeyPic:rowData.photoKeyLpn, rowData.parkingId);
        }
    }, [rowData]);

    return (
        <Dialog
            maxWidth="90%nvp"
            className={classes.container}
            open={showPhoto}
            onClose={handleClose}
        >
            <DialogTitle
                className={
                    classnames(classes.title)
                }
            >
                <span>
                    {moment(new Date(rowData.date)).locale(i18next.language).format('L LTS') + ' - ' + rowData.parkingHost}
                </span>
            </DialogTitle>


            <PMCSnackbar open={snackbarOpen} onClose={handleCloseSnackbar} severity="error" message={'error'}/>

            <DialogContent className={classes.content}>
                <div className={classes.container2} onWheelCapture={onScroll}>
                    <img 
                        src={`data:image/jpg;base64,${photoData?photoData.photo:''}`} 
                        style={{
                            transformOrigin: "0 0",
                            transform: `translate(${pos.x}px, ${pos.y}px) scale(${pos.scale})`
                        }}
                    />
                </div>
            </DialogContent>
            <DialogActions className={classes.actions}>
                <div className={classes.buttonNavigationCancel}>
                    <ActionButton
                        action="closeModal"
                        handleClick={handleClose}
                        className={classes.button}
                    />
                </div>
            </DialogActions>
        </Dialog>

    );
}

PhotoDetail.propTypes = {
    photoData: PropTypes.object.isRequired,
};


const mapStateToProps = (store) => ({
    photoData: store.photoData.photo,
});

const mapDispatchToProps = (dispatch) => bindActionCreators({
    getPhotoDetail
}, dispatch);

export default connect(
    mapStateToProps,
    mapDispatchToProps,
)(PhotoDetail);


