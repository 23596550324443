import infoBarsService from 'services/infobars.service';
import types from '../actionTypes';


export const fetchInfoBars = () => (dispatch, getState) => {
    if (getState().infoBarData.isFetchingInfoBar) {
        return Promise.reject();
    }

    dispatch({
        type: types.INFOBAR_FETCH_REQUEST,
    });

    return infoBarsService.getInfoBars()
        .then((infoBar) => {
            dispatch({
                type: types.INFOBAR_FETCH_SUCCESS,
                payload: {infoBars: infoBar},
            });

            return true;
        })
        .catch((error) => {
            dispatch({
                type: types.INFOBAR_FETCH_FAILT,
                payload: {error},
            });

            throw error;
        });
};

export const fetchInfoBarDetail = (infoBarId) => (dispatch, getState) => {
    if (getState().infoBarData.isFetchingInfoBar) {
        return Promise.reject();
    }

    dispatch({
        type: types.PLAYLIST_DETAIL_FETCH_REQUEST,
    });

    return infoBarsService.getInfoBar(infoBarId)
        .then((playList) => {
            dispatch({
                type: types.INFOBAR_DETAIL_FETCH_SUCCESS,
                payload: {playList},
            });

            return true;
        })
        .catch((error) => {
            dispatch({
                type: types.INFOBAR_DETAIL_FETCH_FAILT,
                payload: {error},
            });

            throw error;
        });
};

export const createInfoBar = (infoBar) => (dispatch, getState) => {
    if (getState().infoBarData.isCreating) {
        return;
    }

    dispatch({
        type: types.INFOBAR_CREATE_REQUEST,
    });

    return infoBarsService.createInfoBar(infoBar)
        .then(() => {
            dispatch({
                type: types.INFOBAR_CREATE_SUCCESS,
                payload: {playList: infoBar},
            });

            return true;
        })
        .catch((error) => {
            dispatch({
                type: types.INFOBAR_CREATE_FAIL,
                payload: {error},
            });

            throw error;
        });
};

export const updateInfoBar = (infoBar) => (dispatch, getState) => {
    if (getState().infoBarData.isUpdating) {
        return;
    }

    dispatch({
        type: types.INFOBAR_UPDATE_REQUEST,
    });

    return infoBarsService.updateInfoBar(infoBar)
        .then(() => {
            dispatch({
                type: types.INFOBAR_UPDATE_SUCCESS,
                payload: {playList: infoBar},
            });
            return true;
        })
        .catch((error) => {
            dispatch({
                type: types.INFOBAR_UPDATE_FAIL,
                payload: {error},
            });

            throw error;
        });
};

// export const deletePlayList = (playList) => (dispatch, getState) => {
//     if (getState().playListData.isDeleting) {
//         return;
//     }

//     dispatch({
//         type: types.PLAYLIST_DELETE_REQUEST,
//     });

//     return playlistsService.deletePlayList(playList)
//         .then(() => {
//             dispatch({
//                 type: types.PLAYLIST_DELETE_SUCCESS,
//                 payload: {playList: playList},
//             });
//             return true;
//         })
//         .catch((error) => {
//             dispatch({
//                 type: types.PLAYLIST_DELETE_FAIL,
//                 payload: {error},
//             });

//             throw error;
//         });
// };