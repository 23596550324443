import branchesService from 'services/branches.service';
import types from '../actionTypes';


export const filterBranches = (filter, page, size) => (dispatch, getState) => {
    if (getState().branchesData.isFetchingAllBranchesList) {
        return;
    }

    dispatch({
        type: types.BRANCHES_FETCH_REQUEST,
    });

    return branchesService.getAllBranchesParam(filter, page, size)
        .then((branches) => {
            dispatch({
                type: types.BRANCHES_PARAM_FETCH_SUCCESS,
                payload: { branches },
            });

            return true;
        })
        .catch((error) => {
            dispatch({
                type: types.BRANCHES_FETCH_FAIL,
                payload: { error },
            });

            throw error;
        });
};

export const createBranch = (branch) => (dispatch, getState) => {
    if (getState().branchesData.isCreating) {
        return;
    }

    dispatch({
        type: types.BRANCH_CREATE_REQUEST,
    });

    return branchesService.createBranch(branch)
        .then((branchNew) => {
            dispatch({
                type: types.BRANCH_CREATE_SUCCESS,
                payload: {branchNew},
            });

            return branchNew;
        })
        .catch((error) => {
            dispatch({
                type: types.SUBJECT_CREATE_FAIL,
                payload: {error},
            });

            throw error;
        });
};

export const updateBranch = (branch) => (dispatch, getState) => {
    if (getState().branchesData.isUpdating) {
        return;
    }

    dispatch({
        type: types.BRANCH_UPDATE_REQUEST,
    });

    return branchesService.updateBranch(branch)
        .then(() => {
            dispatch({
                type: types.BRANCH_UPDATE_SUCCESS,
                payload: {branch},
            });
            return true;
        })
        .catch((error) => {
            dispatch({
                type: types.BRANCH_UPDATE_FAIL,
                payload: {error},
            });

            throw error;
        });
};

export const fetchAllBranches = () => (dispatch, getState) => {
    if (getState().branchesData.isFetchingAllBranchesList) {
        return Promise.reject();
    }

    dispatch({
        type: types.BRANCHES_FETCH_REQUEST,
    });

    return branchesService.getAllBranches()
        .then((branches) => {
            dispatch({
                type: types.BRANCHES_FETCH_SUCCESS,
                payload: { branches },
            });

            return true;
        })
        .catch((error) => {
            dispatch({
                type: types.BRANCHES_FETCH_FAIL,
                payload: { error },
            });

            throw error;
        });
};

export const fetchBranchesBySubject = (subjectId) => (dispatch, getState) => {
    if (getState().branchesData.isFetchingBranchesBySubjectList) {
        return Promise.reject();
    }

    dispatch({
        type: types.BRANCHES_BY_SUBJECT_FETCH_REQUEST,
    });

    return branchesService.getBranchesBySubject(subjectId)
        .then((branchesBySubject) => {
            dispatch({
                type: types.BRANCHES_BY_SUBJECT_FETCH_SUCCESS,
                payload: { branchesBySubject },
            });

            return true;
        })
        .catch((error) => {
            dispatch({
                type: types.BRANCHES_BY_SUBJECT_FETCH_FAIL,
                payload: { error },
            });

            throw error;
        });
};
